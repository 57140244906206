import { FC, useEffect } from 'react';
import { env } from '../../../config/env';

const ZendeskProvider: FC<{ dealId: string }> = ({ dealId, children }) => {
  useEffect(() => {
    (async () => {
      const src = `https://static.zdassets.com/ekr/snippet.js?key=${env.zendesk.accountKey}`;
      const script = document.querySelector(`script[src="${src}"]`);

      if (!script) {
        const s = document.createElement('script');
        s.src = src;
        s.id = 'ze-snippet';
        document.getElementsByTagName('head')[0].appendChild(s);
      }
    })();
  }, []);

  useEffect(() => {
    if (dealId) {
      window.zESettings = {
        webWidget: {
          authenticate: {
            chat: {
              jwtFn: (callback: any) => {
                fetch(`${env.api.url}/estimates/${dealId}/zendesk`)
                  .then((res) => {
                    res
                      .json()
                      .then(({ token }) => {
                        callback(token);
                      })
                      .catch();
                  })
                  .catch();
              },
            },
          },
        },
      };
    }
  }, [dealId]);

  return <>{children}</>;
};

export default ZendeskProvider;
