import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal } from '.';
import { Flex, Text } from 'components/Layout';
import { Button, IconButton } from 'components/Buttons';
import { theme } from 'theme';
import FileDrop from '../FileDrop/FileDrop';
import { mergeFilesToPdf } from '../../utils/pdf';
import { useApi } from '../../hooks/useApi';
import { DocumentTemplate, Procedure, User } from '../../types/resources';
import InputSelect from '../FormTemplate/Fields/InputSelect';
import { InputText } from '../FormTemplate/Fields/InputText';

interface CreateAndUploadModalProps {
  procedure: Procedure;
  opened: boolean;
  onClose: () => void;
  isAdministrative: boolean;
  spouses: User[];
}

interface CustomModalProps {
  large?: boolean;
}

const CustomModal = styled(Modal)<CustomModalProps>`
  /* height: 536px; */
  padding: ${theme.spacing.space24};

  ${(props) =>
    props.large &&
    `
    height: auto;
  `}
`;

const PickerWrapper = styled.div`
  height: 345px;
  width: 600px;
`;

const CreateAndUploadModal: FC<CreateAndUploadModalProps> = ({
  procedure,
  opened,
  onClose,
  isAdministrative,
  spouses,
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[]>([]);
  const [template, setTemplate] = useState<DocumentTemplate | undefined>(
    undefined,
  );
  const [spouseId, setSpouseId] = useState<string | null | undefined>(
    undefined,
  );
  const [title, setTitle] = useState<string | null | undefined>(undefined);
  const spousesOptions = [
    { label: 'Document commun', value: null },
    ...spouses.map((s) => ({
      label: s.full_name,
      value: s.id,
    })),
  ];

  const { execute: createDocument, state: createDocumentState } = useApi(
    `/procedures/${procedure.id}/documents`,
    {
      method: 'POST',
      onSuccess: () => {
        onClose();
      },
    },
  );

  const { execute: getDocumentsTemplates, state: getDocumentsTemplatesState } =
    useApi<DocumentTemplate[]>(`/documents-templates`);
  const documentsTemplates = getDocumentsTemplatesState.data?.value ?? [];

  const documentTemplatesOptions = documentsTemplates.map((dt) => ({
    label: t(`documents_templates.${dt.id}`),
    value: dt.id,
  }));

  const onDropFiles = (files: File[]) => {
    if (files.length) {
      setFiles(files);
    }
  };

  const confirm = useCallback(() => {
    if (files.length && template) {
      mergeFilesToPdf(files).then((value) => {
        const file = new File([value], 'document.pdf', {
          type: 'application/pdf',
        });

        const body = new FormData();
        body.set('document_template_id', template.id);
        body.set('file', file);
        spouseId && body.set('spouse_id', spouseId);

        createDocument({ body });
      });
    }
  }, [files, template, spouseId]);

  useEffect(() => {
    if (opened) {
      getDocumentsTemplates({ query: { is_administrative: isAdministrative } });
      setFiles([]);
      setTemplate(undefined);
    }
  }, [opened]);

  return (
    <CustomModal opened={opened} onClose={onClose}>
      <Flex
        alignItems="center"
        justify="between"
        paddingBottom={{ xs: 'space24' }}
      >
        <Flex alignItems="center">
          <IconButton
            iconName="Document"
            color={theme.colors.salmon2}
            backgroundColor={theme.colors.salmon3}
            rounded
          />
          <Text
            fontStyle="heading5"
            content={'Ajout un nouveau document'}
            marginLeft={{ xs: 'space16' }}
            marginRight={{ xs: 'space24' }}
          />
        </Flex>
        <Flex style={{ columnGap: theme.spacing.space8 }}>
          <Button
            content="Confirmer"
            iconLeft={{ name: 'Edit' }}
            primary
            onClick={confirm}
            $loading={createDocumentState.loading}
            disabled={!template || !files.length}
          />
          <Button
            content={t('upload.close')}
            iconLeft={{ name: 'CloseSquare' }}
            onClick={onClose}
          />
        </Flex>
      </Flex>
      <Flex marginBottom={{ xs: 'space16' }}>
        <InputSelect
          onChange={(value) => {
            const selectedTemplate = documentsTemplates.find(
              (dt) => dt.id === value,
            );
            setTemplate(selectedTemplate);
            setTitle(selectedTemplate?.title ?? '');
          }}
          options={documentTemplatesOptions}
          placeholder={t('documents.request_document.type_placeholder')}
          label={t('documents.request_document.type')}
          size="small"
          value={template?.id}
          required
          width="100%"
        />
      </Flex>

      {template !== undefined && (
        <Flex marginBottom={{ xs: 'space16' }}>
          <InputSelect
            onChange={(value) => {
              const selectedSpouse =
                value === null
                  ? value
                  : spouses.find((s) => s.id === value)?.id;
              setSpouseId(selectedSpouse);
              setTitle(template.title);
            }}
            options={spousesOptions}
            placeholder={t('upload.spouse_selection_placeholder')}
            label={t('upload.spouse_selection')}
            size="small"
            value={template?.id}
            required
            width="100%"
          />
        </Flex>
      )}

      {template !== undefined && spouseId !== undefined && (
        <Flex marginBottom={{ xs: 'space16' }}>
          <InputText
            onChange={(value) => {
              setTitle(value.target.value ?? '');
            }}
            placeholder={t('upload.title_selection_placeholder')}
            label={t('upload.title_selection')}
            value={title ?? undefined}
            $size="small"
            labelFontStyle={'label'}
            required
            width="100%"
          />
        </Flex>
      )}

      <Flex direction={{ xs: 'column' }} justify="between">
        <PickerWrapper>
          <FileDrop
            onDrop={onDropFiles}
            multiple={true}
            maxFiles={5}
            disabled={createDocumentState.loading}
          />
        </PickerWrapper>
      </Flex>
    </CustomModal>
  );
};

export default CreateAndUploadModal;
