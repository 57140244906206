import { FC, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { Breadcrumbs, UserUpdateForm } from 'container/admin';
import format from 'date-fns/format';
import { useRouteMatch } from 'react-router';
import { get } from 'lodash';
import { useApi } from '../../hooks/useApi';
import { Button, InlineButton } from '../../components/Buttons';
import { theme } from '../../theme';
import { Department, InternalUser, Role, User } from '../../types/resources';
import styled from 'styled-components';
import { Modal, ModalHeader } from '../../components/Modal';
import { ConfirmForm } from '../../components/ConfirmPopin/ConfirmPopin';
import useToaster from '../../hooks/useToaster';
import { InputText } from '../../components/FormTemplate/Fields/InputText';

const ActionsContainer = styled.div`
  margin-top: ${theme.spacing.space48};
  border-radius: 8px;
  padding: ${theme.spacing.space16};
  background-color: ${theme.colors.salmon3};
`;

type ModalLawyerUpdateOtpKeyProps = {
  opened: boolean;
  loading?: boolean;
  setOpened: (opened: boolean) => void;
  onUpdate: (value: string) => void;
};

const ModalLawyerUpdateOtpKey: FC<ModalLawyerUpdateOtpKeyProps> = ({
  opened,
  setOpened,
  loading,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    setValue('');
  }, [opened]);

  return (
    <Modal opened={opened} full onClose={() => setOpened(false)}>
      <ModalHeader
        content={t('lawyer.ar24.update_otp_key_title')}
        iconName="Message"
        onClose={() => setOpened(false)}
      />
      <Text fontStyle="body1" marginTop={{ xs: 'space16' }}>
        <Trans i18nKey="lawyer.ar24.update_otp_key_description" />
      </Text>

      <Flex marginTop={{ xs: 'space16' }}>
        <InputText
          placeholder={'XXXX XXXX XXXX XXXX XXXX XXXX XXXX XXXX'}
          width={'100%'}
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
      </Flex>

      <Flex marginTop={{ xs: 'space40' }} justify={'end'}>
        <Button
          content={t('lawyer.ar24.update_otp_key_confirm')}
          marginRight={{ xs: 'space16' }}
          disabled={!value}
          onClick={() => {
            if (value) onUpdate(value);
          }}
          $loading={loading}
        />
        <Button
          content={t('lawyer.ar24.update_otp_key_cancel')}
          onClick={() => setOpened(false)}
          primary
        />
      </Flex>
    </Modal>
  );
};

const getResourceType = (role: Role) => {
  switch (role) {
    case Role.LAWYER:
      return `lawyers`;
    case Role.MANAGER:
      return `managers`;
    default:
      return `users`;
  }
};

const UsersDetails: FC = () => {
  const { t } = useTranslation();
  const { success, error } = useToaster();
  const match = useRouteMatch();
  const userId = get(match, 'params.id', '');
  const [resetPasswordModalIsOpen, setResetPasswordModalIsOpen] =
    useState<boolean>(false);
  const [updateOtpKeyModal, toggleUpdateOtpKeyModal] = useState<boolean>(false);

  const {
    execute: resetPasswordRequest,
    state: { loading },
  } = useApi(`/users/${userId}/reset-password`, {
    method: 'GET',
  });
  const { execute: getDepartments, state: getDepartmentsState } = useApi<
    Department[]
  >('/departments', { cache: true });
  const departments = getDepartmentsState.data?.value ?? [];

  const { execute: getUser, state: getUserState } = useApi<User>(
    `/users/${userId}`,
  );
  const user = getUserState.data?.value;

  const resource = user?.role ? getResourceType(user?.role) : undefined;
  const resourceUrl = resource ? `/${resource}/${userId}` : undefined;

  const { execute: getConcreteUser, state: getConcreteUserState } =
    useApi<InternalUser>(resourceUrl ?? '');
  const concreteUser = getConcreteUserState.data?.value;

  const { execute: updateLawyerOtpKey, state: updateLawyerOtpKeyState } =
    useApi(`/lawyers/${concreteUser?.id}/ok`, {
      method: 'PUT',
      onSuccess: () => {
        toggleUpdateOtpKeyModal(false);
        success(t('lawyer.ar24.update_otp_key_success'));
      },
    });

  const updateLawyerOtpKeyCb = useCallback(
    (value: string) => {
      if (concreteUser?.role === Role.LAWYER) {
        updateLawyerOtpKey({
          body: { ok: value },
        });
      }
    },
    [concreteUser],
  );

  useEffect(() => {
    getUser();
    getDepartments();
  }, []);

  useEffect(() => {
    if (resourceUrl) {
      getConcreteUser({ endpoint: resourceUrl });
    }
  }, [resourceUrl]);

  const handleSendResetPassword = () => {
    setResetPasswordModalIsOpen(true);
  };

  const onCloseResetPassword = () => {
    setResetPasswordModalIsOpen(false);
  };

  const handleResetPasswordConfirm = async () => {
    resetPasswordRequest({
      onSuccess: () => {
        onCloseResetPassword();
        success(t('user.details.reset_password.success'));
      },
      onError: () => {
        error(t('user.details.reset_password.error'));
      },
    });
  };

  const handlePhoneCall = useCallback(() => {
    if (concreteUser?.phone) {
      window.open(`tel:${concreteUser.phone}`);
    }
  }, [concreteUser?.phone]);

  const handleSendMail = useCallback(() => {
    if (concreteUser?.email) {
      window.open(`mailto:${concreteUser.email}`);
    }
  }, [concreteUser?.email]);

  return (
    <>
      <Modal opened={resetPasswordModalIsOpen} onClose={onCloseResetPassword}>
        <ConfirmForm
          title={t('user.details.reset_password.title')}
          description={t('user.details.reset_password.description')}
          confirmText={t('user.details.reset_password.confirm')}
          handleSubmit={handleResetPasswordConfirm}
          setModalIsOpen={setResetPasswordModalIsOpen}
          loading={loading}
        />
      </Modal>
      <Breadcrumbs name={concreteUser?.full_name} />
      <Flex marginBottom={{ xs: 'space48' }} justify="between">
        <Text content={t('user.details.title')} fontStyle="heading2" />

        {concreteUser && (
          <Flex direction={{ xs: 'column' }} alignItems={'end'}>
            <Text
              content={t('updated_at', {
                updated_at: format(
                  new Date(concreteUser.updated_at),
                  "dd/MM/YYY 'à' HH:mm",
                ),
              })}
              fontStyle="body3"
            />
          </Flex>
        )}
      </Flex>

      {concreteUser && (
        <UserUpdateForm user={concreteUser} departments={departments} />
      )}

      {concreteUser && (
        <ActionsContainer>
          <Flex direction={{ xs: 'column' }}>
            <InlineButton
              onClick={handleSendResetPassword}
              iconColor={theme.colors.salmon1}
              text={t('user.details.reset_password.label')}
              backgroundColor={theme.colors.white}
              iconName="ArrowRight"
              hoverBackgroundColor={theme.colors.salmon2}
              hoverIconColor={theme.colors.white}
              style={{ width: 'fit-content' }}
            />

            {concreteUser.email && (
              <InlineButton
                onClick={handleSendMail}
                iconColor={theme.colors.salmon1}
                text={t('user.send_mail')}
                backgroundColor={theme.colors.white}
                iconName="Send"
                hoverBackgroundColor={theme.colors.salmon2}
                hoverIconColor={theme.colors.white}
                style={{ width: 'fit-content', marginTop: '12px' }}
              />
            )}

            {concreteUser.phone && (
              <InlineButton
                onClick={handlePhoneCall}
                iconColor={theme.colors.salmon1}
                text={t('user.call')}
                backgroundColor={theme.colors.white}
                iconName="Call"
                hoverBackgroundColor={theme.colors.salmon2}
                hoverIconColor={theme.colors.white}
                style={{ width: 'fit-content', marginTop: '12px' }}
              />
            )}

            {concreteUser.role === Role.LAWYER && (
              <InlineButton
                onClick={() => toggleUpdateOtpKeyModal(true)}
                iconColor={theme.colors.salmon1}
                text={t('lawyer.ar24.update_otp_key')}
                backgroundColor={theme.colors.white}
                iconName="Unlock"
                hoverBackgroundColor={theme.colors.salmon2}
                hoverIconColor={theme.colors.white}
                style={{ width: 'fit-content', marginTop: '12px' }}
              />
            )}
          </Flex>
        </ActionsContainer>
      )}

      <ModalLawyerUpdateOtpKey
        opened={updateOtpKeyModal}
        setOpened={toggleUpdateOtpKeyModal}
        loading={updateLawyerOtpKeyState.loading}
        onUpdate={updateLawyerOtpKeyCb}
      />
    </>
  );
};

export default UsersDetails;
