import { FC, useMemo } from 'react';
import { Form } from 'wrapper/FormWrapper';

// schema
import { SchemaQuote, UiSchemaQuote } from 'schemas';

// types
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FormValidation } from '@rjsf/core';
import analytics, { handleFormDataEvent } from 'utils/analytics';
import { CoupleSituation } from 'schemas/Quote';
import { useApi } from '../../../hooks/useApi';
import { scrollToTop } from '../../../utils/layout';
import { getRouteWithParams } from '../../../utils/router';
import { PublicAppRoutes } from '../../../PublicApp';
import { useAuth } from '../../../hooks/useAuth';
import {
  Department,
  Estimate,
  Option,
  Plan,
  ProductCode,
} from '../../../types/resources';

export interface FormDataQuote {
  agreecorrespondence: boolean;
  choice0: 'true' | 'false';
  choice1: 'true' | 'false';
  region: Department;
  coupleSituation: CoupleSituation;
  properties: boolean;
  email: string;
  phone?: string;
  nbAdultChildren?: number;
  nbYoungChildren?: number;
  plan: Plan;
  options?: Option[];
}

const removeElement = (index: number, array: any[]) => {
  const newSchema = [...array];
  newSchema.splice(index, 1);
  return newSchema;
};

const QuoteForm: FC = () => {
  const { user } = useAuth();
  const { schema, uiSchema } = useMemo(() => {
    return {
      schema: user
        ? removeElement(SchemaQuote.length - 1, SchemaQuote)
        : SchemaQuote,
      uiSchema: user
        ? removeElement(UiSchemaQuote.length - 1, UiSchemaQuote)
        : UiSchemaQuote,
    };
  }, [user]);

  const { execute: createEstimate, state: createEstimateState } = useApi(
    '/estimates',
    { method: 'POST' },
  );

  const { execute: getEstimate, state: getEstimateState } =
    useApi<Estimate>('/estimates');

  const history = useHistory();
  const { t } = useTranslation();

  const validate = (formData: FormDataQuote, errors: FormValidation) => {
    if (
      formData.coupleSituation === CoupleSituation.coupleWithChildren &&
      formData.nbAdultChildren === 0 &&
      formData.nbYoungChildren === 0
    ) {
      errors.coupleSituation.addError('Veuillez saisir au moins un enfant');
    }

    return errors;
  };

  const handleSubmit = async (
    formData: FormDataQuote,
    maxStep: boolean,
    step: number,
  ) => {
    handleFormDataEvent(formData, step);

    if (maxStep) {
      analytics?.track('devis', {
        user_email: formData.email,
        formule: [
          {
            id_formule: formData.plan.id,
            code_formule: formData.plan.code,
            name_formule: formData.plan.label,
          },
        ],
        option_express: formData.options?.some(
          (o) => o.code === ProductCode.Express,
        ),
        option_premium: formData.options?.some(
          (o) => o.code === ProductCode.Premium,
        ),
      });

      createEstimate({
        body: {
          customer_id: user?.id,
          prospect: {
            email: user?.email ?? formData.email,
            phone: user?.phone ?? formData.phone,
          },
          adult_children: formData.nbAdultChildren ?? 0,
          minor_children: formData.nbYoungChildren ?? 0,
          has_real_estate: formData.properties,
          plan_id: formData.plan.id,
          options_ids: formData.options?.map((o) => o.id) ?? [],
          department_code: formData.region.code,
        },
        onSuccess: (result) => {
          if (result.location) {
            getEstimate({
              endpoint: result.location,
              onSuccess: (data) => {
                scrollToTop();
                history.push(
                  getRouteWithParams(PublicAppRoutes.QUOTE_RESUME, {
                    id: data.value.id,
                  }),
                );
              },
            });
          }
        },
      });
    }
  };

  return (
    <Form
      schema={schema}
      uiSchema={uiSchema}
      handleSubmit={handleSubmit}
      customMaxStep={schema.length + 2}
      lastTextSubmit={t('quote.recap_step')}
      loadingSubmit={createEstimateState.loading || getEstimateState.loading}
      validate={validate}
      liveChange
      liveValidate={false}
      autoNavigate
      disableAutoNavigationAtStep={6}
      showMobileStepBreadcrumb
    />
  );
};

export default QuoteForm;
