import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Flex, Line, Row, Text } from 'components/Layout';
import { ListItem } from 'components/List';
import { theme } from 'theme';
import { formatDate } from 'utils/formatDate';
import { downloadFileNew } from 'utils/downloadFile';
import { FileModal } from 'components/Modal';
import AdministrativeDocumentsInfo from './AdministrativeDocumentsInfo';
import { useCustomer } from '../../../hooks/useCustomer';
import {
  DocumentFull,
  DocumentTemplateId,
  ProcedureStepType,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import { formatDocumentTitle } from '../../../utils/format';

const DocumentsList: FC = () => {
  const { t } = useTranslation();
  const { customer } = useCustomer();
  const [previewModalState, setPreviewModalState] = useState<boolean>(false);
  const [document, setDocument] = useState<DocumentFull>();

  const onPreviewClose = () => {
    setDocument(undefined);
    setPreviewModalState(false);
  };

  const { execute: getDocuments, state: getDocumentsState } = useApi<
    DocumentFull[]
  >(`/procedures/${customer?.procedure?.id}/documents?is_administrative=false`);
  const documents = getDocumentsState.data?.value ?? [];

  const onViewDocument = (document: DocumentFull) => {
    setDocument(document);
    setPreviewModalState(true);
  };

  const { execute: downloadDocument } = useApi<{ url: string }>(``);

  const onDownloadDocument = (document: DocumentFull) => {
    if (document.file_id && customer?.procedure_id) {
      downloadDocument({
        endpoint: `/procedures/${customer.procedure_id}/documents/${document.id}/download`,
        onSuccess: async (result) => {
          try {
            await downloadFileNew(
              result.value.url,
              formatDocumentTitle(document),
            );
          } catch (e) {
            console.error(e);
          }
        },
      });
    }
  };

  useEffect(() => {
    if (customer?.procedure?.id) {
      getDocuments();
    }
  }, [customer?.procedure?.id]);

  if (!customer?.procedure) return null;

  return (
    <Row marginTop={{ xs: 'space32' }}>
      <Col xs={12} md={4}>
        <AdministrativeDocumentsInfo
          showDownloadButton={
            customer.procedure.step === ProcedureStepType.CLOSED
          }
          filenameDownload={`${customer.procedure.reference}-procedure-divorce`}
          opportunityId={customer.procedure.id}
        />
      </Col>
      <Col xs={12} md={8}>
        <Flex
          direction={{ xs: 'column' }}
          width="100%"
          marginBottom={{ xs: 'space16' }}
        >
          <Flex
            marginBottom={{ xs: 'space16' }}
            justify="between"
            alignItems="center"
          >
            <Text
              fontStyle="heading5"
              weight="bold"
              content={t('administrative_documents.your_documents')}
            />
            <Text
              fontStyle="body2"
              weight="medium"
              content={t('administrative_documents.documents_to_sign', {
                count: documents.length,
              })}
              color={theme.colors.gray6}
            />
          </Flex>
          <Flex marginBottom={{ xs: 'space24' }}>
            <Line />
          </Flex>
          {documents.map((document) => {
            const date = new Date(document.updated_at ?? document.created_at);
            const buttons = [
              {
                onClick: () => {
                  onDownloadDocument(document);
                },
                content: t('administrative_documents.buttons.download'),
              },
              {
                onClick: () => {
                  onViewDocument(document);
                },
                content: t('administrative_documents.buttons.see'),
              },
            ];
            const primary =
              document.document_template_id &&
              [
                DocumentTemplateId.NOTARY_LETTER,
                DocumentTemplateId.MARITAL_STATUS,
                DocumentTemplateId.AGREEMENT_SIGNED,
              ].includes(document.document_template_id);

            return (
              <Flex marginBottom={{ xs: 'space16' }} key={document.id}>
                <ListItem
                  title={formatDocumentTitle(document)}
                  iconName="Paper"
                  dates={[formatDate(date)]}
                  buttons={buttons}
                  iconColor={primary ? theme.colors.salmon1 : undefined}
                />
              </Flex>
            );
          })}
          {document && (
            <FileModal
              opened={previewModalState}
              onClose={onPreviewClose}
              hideTip
              document={document}
              procedure={customer.procedure}
              refetchDocuments={() => {}}
            />
          )}
        </Flex>
      </Col>
    </Row>
  );
};

export default DocumentsList;
