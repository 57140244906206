import { FC } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components';

// Components
import UnregisterHeader from 'components/Layout/Header/UnregisterHeader';

// Pages
import {
  Quote,
  QuoteResumePage,
  Register,
  SignIn,
  ForgottenPassword,
  AccountActivation,
  PasswordChange,
} from 'pages/public';

// Schema
import Router from 'types/router.types';

const Container = styled.div`
  padding-top: 70px;
  height: auto;
`;

export enum PublicAppRoutes {
  REGISTER = '/inscription',
  SIGN_IN = '/connexion',
  FORGOTTEN_PASSWORD = '/mot-de-passe-oublie',
  PASSWORD_CHANGE = '/reinitialisation-mot-de-passe',
  ACCOUNT_ACTIVATION = '/activation-compte',
  QUOTE = '/devis',
  QUOTE_RESUME = '/recapitulatif-devis/:id',
}

export const publicRoutes: Record<PublicAppRoutes, Router> = {
  [PublicAppRoutes.REGISTER]: {
    routeProps: {
      path: PublicAppRoutes.REGISTER,
      exact: true,
      render: Object.assign(() => <Register />, { displayName: 'Register' }),
    },
    isMobile: true,
  },
  [PublicAppRoutes.SIGN_IN]: {
    routeProps: {
      path: PublicAppRoutes.SIGN_IN,
      exact: true,
      render: Object.assign(() => <SignIn />, { displayName: 'SignIn' }),
    },
    isMobile: true,
  },
  [PublicAppRoutes.FORGOTTEN_PASSWORD]: {
    routeProps: {
      path: PublicAppRoutes.FORGOTTEN_PASSWORD,
      exact: true,
      render: Object.assign(() => <ForgottenPassword />, {
        displayName: 'ForgottenPassword',
      }),
    },
    isMobile: true,
  },
  [PublicAppRoutes.PASSWORD_CHANGE]: {
    routeProps: {
      path: PublicAppRoutes.PASSWORD_CHANGE,
      exact: true,
      render: Object.assign(() => <PasswordChange />, {
        displayName: 'PasswordChange',
      }),
    },
    isMobile: true,
  },
  [PublicAppRoutes.ACCOUNT_ACTIVATION]: {
    routeProps: {
      path: PublicAppRoutes.ACCOUNT_ACTIVATION,
      exact: true,
      render: Object.assign(() => <AccountActivation />, {
        displayName: 'AccountActivation',
      }),
    },
    isMobile: true,
  },
  [PublicAppRoutes.QUOTE]: {
    routeProps: {
      path: PublicAppRoutes.QUOTE,
      exact: true,
      render: Object.assign(() => <Quote />, { displayName: 'Quote' }),
    },
    isMobile: true,
  },
  [PublicAppRoutes.QUOTE_RESUME]: {
    routeProps: {
      path: PublicAppRoutes.QUOTE_RESUME,
      exact: true,
      component: QuoteResumePage,
    },
    isMobile: true,
  },
};

const PublicApp: FC = () => {
  const location = useLocation();

  return (
    <>
      <UnregisterHeader />
      <Container>
        <Switch>
          {Object.values(publicRoutes).map((route) => (
            <Route
              key={`clientRoute-${route.routeProps.path}`}
              {...route.routeProps}
            />
          ))}
          <Redirect
            to={{
              pathname: PublicAppRoutes.SIGN_IN,
              search: `from=${location.pathname}`,
            }}
          />
        </Switch>
      </Container>
    </>
  );
};

export default PublicApp;
