import { FC, useEffect, useMemo, useState } from 'react';
import { Column, SortingRule } from 'react-table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import Table from 'components/Table/Table';

import { useApi } from '../../../hooks/useApi';
import format from 'date-fns/format';
import { Notification, SortDirection } from '../../../types/resources';

type NotificationsListProps = {
  pageSize: number;
  type: string | null;
  status: string | null;
  search: string | null;
};

const NotificationsList: FC<NotificationsListProps> = ({
  pageSize,
  search,
  type,
  status,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [sortKey, setSortKey] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    undefined,
  );
  const history = useHistory();
  const {
    execute: getNotifications,
    state: { data },
  } = useApi<Notification[]>('/notifications');
  const notifications = data?.value;

  useEffect(() => {
    getNotifications({
      query: {
        search: search || undefined,
        type: type || undefined,
        status: status || undefined,
        page: page !== undefined ? page + 1 : 1,
        items: pageSize,
        sort: sortKey,
        direction: sortDirection,
      },
    });
  }, [search, type, status, page, sortKey, sortDirection]);

  const onLoadMore = ({
    pageIndex,
    sortBy,
  }: {
    pageIndex?: number;
    sortBy?: SortingRule<Notification>[];
  }) => {
    if (pageIndex !== undefined) {
      setPage(pageIndex);
    }

    const _sortKey = sortBy?.[0]?.id;
    if (_sortKey !== undefined) {
      setSortKey(_sortKey);
    }

    const _sortDesc = sortBy?.[0]?.desc;
    if (_sortDesc !== undefined) {
      setSortDirection(_sortDesc ? SortDirection.DESC : SortDirection.ASC);
    }
  };

  const columns = useMemo<Column<Notification>[]>(
    () => [
      {
        Header: t('notification.type'),
        accessor: 'type',
        disableSortBy: true,
        width: 180,
        Cell: ({ value }) => t(`notification.types.${value}`),
      },
      {
        Header: t('notification.status'),
        accessor: 'status',
        width: 80,
        Cell: ({ value }) => t(`notification.statuses.${value}`),
      },
      {
        Header: t('notification.email'),
        accessor: 'email',
        width: 150,
        disableSortBy: true,
      },
      {
        Header: t('notification.created_at'),
        accessor: 'created_at',
        width: 100,
        Cell: ({ value }) =>
          value ? format(new Date(value), "dd/MM/YYY 'à' HH:mm") : 'N/A',
      },
    ],
    [history],
  );

  return (
    <Table<Notification>
      data={notifications || []}
      columns={columns}
      pageSize={pageSize}
      totalCount={data?.pagination?.total || 0}
      loadMore={onLoadMore}
    />
  );
};

export default NotificationsList;
