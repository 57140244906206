import { FC } from 'react';
import { useHistory } from 'react-router';

import { getRouteWithParams } from 'utils/router';
import { AdminAppRoutes } from 'AdminApp';

import { Dropdown } from 'components/Select';
import { Form, FormFull } from '../../../types/resources';

interface FormsDropdownProps {
  procedure_id: string;
  forms: Form[];
  form: FormFull;
}

const FormsDropdown: FC<FormsDropdownProps> = ({
  procedure_id,
  forms,
  form,
}) => {
  const history = useHistory();

  if (forms.length === 0) return <></>;

  const options = forms.map((form) => {
    return {
      value: form.id,
      label: form.name,
    };
  });
  const onChange = (value: string) => {
    history.push({
      pathname: getRouteWithParams(AdminAppRoutes.FORM, {
        opportunityId: procedure_id,
        formId: value,
      }),
      search: `?switchForm=true`,
    });
  };
  return <Dropdown options={options} id={form.id} onChange={onChange} />;
};

export default FormsDropdown;
