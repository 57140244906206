import { FC, useEffect } from 'react';
import { env } from '../../../config/env';
import { useAuth } from '../../../hooks/useAuth';

const CrispProvider: FC = ({ children }) => {
  const { user } = useAuth();
  const enabled = !!env.crisp.websiteId;

  const onOpen = () => {
    if (!enabled) return;

    window.$crisp.push(['set', 'session:event', ['start-bot']]);
  };

  const onInit = () => {
    if (!enabled) return;
  };

  useEffect(() => {
    if (!enabled) return;

    window.$crisp = [];
    window.CRISP_TOKEN_ID = user?.id;
    window.CRISP_WEBSITE_ID = env.crisp.websiteId;
    window.CRISP_RUNTIME_CONFIG = {
      locale: 'fr',
    };

    (() => {
      const src = 'https://client.crisp.chat/l.js';
      const script = document.querySelector(`script[src="${src}"]`);

      if (!script) {
        const s = document.createElement('script');
        s.src = src;
        s.async = true;
        document.getElementsByTagName('head')[0].appendChild(s);
      }
    })();

    window.$crisp.push(['safe', true]);
    window.$crisp.push(['on', 'chat:opened', onOpen]);
    window.$crisp.push(['on', 'session:loaded', onInit]);

    window.$crisp.push(['set', 'user:email', [user?.email]]);
  }, []);

  return <>{children}</>;
};

export default CrispProvider;
