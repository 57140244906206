import { FC, useCallback, useEffect } from 'react';
import { get } from 'lodash';
import { useRouteMatch } from 'react-router';

/** Container */
import {
  AdministrativeDocumentCard,
  OpportunityHeader,
  OpportunityViews,
} from 'container/admin';

/** Components */
import { Col, Row } from 'components/Layout';
import { RolesOpportunityList, StepperOpportunity } from 'container/common';
import NotesOpportunity from 'container/common/NotesOpportunity/NotesOpportunity';
import LawyerConsultationCard from 'container/admin/cards/LawyerConsultationCard';
import { useApi } from '../../hooks/useApi';
import {
  CommentFull,
  Conversation,
  EstimateFull,
  ProcedureFull,
  ProcedureSpouseFull,
} from '../../types/resources';
import { useAuth } from '../../hooks/useAuth';

const OpportunityPage: FC = () => {
  const { user } = useAuth();
  const match = useRouteMatch();
  const procedureId = get(match, 'params.id', '');

  const { execute: getProcedure, state: getProcedureState } =
    useApi<ProcedureFull>(`/procedures/${procedureId}`);
  const procedure = getProcedureState.data?.value;

  const { execute: getProcedureSpouses, state: getProcedureSpousesState } =
    useApi<ProcedureSpouseFull[]>(`/procedures/${procedureId}/spouses`);
  const spouses = getProcedureSpousesState.data?.value;

  const {
    execute: getProcedureConversations,
    state: getProcedureConversationsState,
  } = useApi<Conversation[]>(`/procedures/${procedureId}/conversations`);
  const conversations = getProcedureConversationsState.data?.value;

  const { execute: getEstimate, state: getEstimateState } =
    useApi<EstimateFull>(`/procedures/${procedureId}/estimate`);
  const estimate = getEstimateState.data?.value;

  const { execute: getProcedureComments, state: getProcedureCommentsState } =
    useApi<CommentFull[]>(`/procedures/${procedureId}/comments`);
  const commentsPagination = getProcedureCommentsState.data?.pagination;
  const comments = getProcedureCommentsState.data?.value;

  const getEstimateCb = useCallback(() => {
    if (procedure?.estimate_id) {
      getEstimate();
    }
  }, [procedure]);

  useEffect(() => {
    if (user) {
      getProcedureSpouses();
      getProcedureComments();
      getProcedureConversations();
      getProcedure();
    }
  }, [user]);

  useEffect(() => {
    if (procedure) {
      getEstimateCb();
    }
  }, [procedure]);

  if (
    !procedure ||
    !estimate ||
    !spouses ||
    !comments ||
    !commentsPagination ||
    !conversations
  )
    return null;

  return (
    <>
      <OpportunityHeader procedure={procedure} />
      <OpportunityViews
        procedure={procedure}
        spouses={spouses}
        refetchProcedure={getProcedure}
      >
        <Row marginBottom={{ xs: 'space32' }}>
          <Col xs={12}>
            <StepperOpportunity
              estimate={estimate}
              conversations={conversations}
              procedure={procedure}
            />
          </Col>
        </Row>
        <Row marginBottom={{ xs: 'space32' }}>
          <Col xs={12}>
            <NotesOpportunity
              procedure={procedure}
              comments={comments}
              commentsPagination={commentsPagination}
              refetch={getProcedureComments}
            />
          </Col>
        </Row>
        <Row marginBottom={{ xs: 'space32' }}>
          <Col xs={12}>
            <RolesOpportunityList
              procedure={procedure}
              estimate={estimate}
              refetchProcedure={getProcedure}
            />
          </Col>
        </Row>
        <Row marginBottom={{ xs: 'space32' }}>
          <Col xs={12} md={5}>
            <AdministrativeDocumentCard />
          </Col>
          <Col xs={12} md={5}>
            <LawyerConsultationCard
              procedure={procedure}
              spouses={spouses}
              onUpdateSpouses={getProcedureSpouses}
            />
          </Col>
        </Row>
      </OpportunityViews>
    </>
  );
};

export default OpportunityPage;
