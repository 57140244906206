import { FC, useEffect } from 'react';
import { theme } from 'theme';
import styled from 'styled-components';

// Components
import { Text, Flex } from 'components/Layout';
import { IconButton } from 'components/Buttons';

// Query
import { md } from 'theme/styles/mediaQueries';
import { useApi } from '../../../hooks/useApi';
import { Form } from '../../../types/resources';

const Container = styled(Flex)`
  display: none;
  ${md(`
    display: flex;
    position: absolute;
    margin: 0 auto;
    width: calc(100% - ${theme.spacing.space72} * 2);
    user-select: none;
    z-index: -1;
  `)}
`;

interface FormTitleHeaderProps {
  id: string;
  procedureId: string;
}

export const FormTitleHeader: FC<FormTitleHeaderProps> = ({
  id,
  procedureId,
}) => {
  const { execute: getForm, state: getFormState } = useApi<Form>(
    `/procedures/${procedureId}/forms/${id}`,
    { cache: true },
  );
  const form = getFormState.data?.value;

  useEffect(() => {
    getForm();
  }, []);

  return (
    <Container alignItems="center" justify="center">
      <IconButton
        rounded
        iconName="User"
        color={theme.colors.salmon2}
        backgroundColor={theme.colors.salmon3}
      />
      <Text
        marginLeft={{ xs: 'space16' }}
        content={form?.name}
        fontStyle="heading5"
      />
    </Container>
  );
};
