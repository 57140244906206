import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Container
import { ProspectsList, ProspectsListFilters } from 'container/admin';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { Button } from '../../components/Buttons';
import { AdminAppRoutes } from '../../AdminApp';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';

const Prospects: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const query = useQueryParams();
  const pageSize = 10;
  const search = query.get('search');

  return (
    <>
      <Flex justify="between">
        <Text content={t('prospect.prospects')} fontStyle="heading2" />
        <Button
          content={t('prospect.add_new')}
          iconLeft={{ name: 'Plus' }}
          onClick={() => history.push(AdminAppRoutes.PROSPECTS_ADD)}
        />
      </Flex>
      <ProspectsListFilters />

      <Flex marginTop={{ xs: 'space32' }}>
        <ProspectsList pageSize={pageSize} search={search} />
      </Flex>
    </>
  );
};

export default Prospects;
