export const getInitialName = (fullName: string): string => {
  const splittedName = fullName.trim().split(/[- ]/);

  if (splittedName.length === 2) {
    return `${
      splittedName[0].charAt(0) + splittedName[1].substring(0, 2)
    }`.toUpperCase();
  }

  if (splittedName.length >= 3) {
    return `${
      splittedName[0].charAt(0) +
      splittedName[1].charAt(0) +
      splittedName[2].charAt(0)
    }`.toUpperCase();
  }

  return '';
};
