import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Container
import { CustomersList, CustomersListFilters } from 'container/admin';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { Button } from '../../components/Buttons';
import { AdminAppRoutes } from '../../AdminApp';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';

const Customers: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const query = useQueryParams();
  const search = query.get('search');

  return (
    <>
      <Flex justify="between">
        <Text content={t('customer.customers')} fontStyle="heading2" />
        <Button
          content={t('customer.add.new')}
          iconLeft={{ name: 'Plus' }}
          onClick={() => history.push(AdminAppRoutes.CUSTOMERS_ADD)}
        />
      </Flex>
      <CustomersListFilters />
      <Flex marginTop={{ xs: 'space32' }}>
        <CustomersList search={search} pageSize={10} />
      </Flex>
    </>
  );
};

export default Customers;
