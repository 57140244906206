import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import ProductsList from 'container/admin/lists/ProductsList';
import ProductsListFilter from 'container/admin/filters/ProductsListFilter';
import { Button } from '../../components/Buttons';
import { AdminAppRoutes } from '../../AdminApp';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';

const Products: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const query = useQueryParams();
  const pageSize = 10;
  const search = query.get('search');
  const type = query.get('type');

  return (
    <>
      <Flex justify="between">
        <Text content={t('product.products')} fontStyle="heading2" />
        <Button
          content={t('product.add_new')}
          iconLeft={{ name: 'Plus' }}
          onClick={() => history.push(AdminAppRoutes.PRODUCTS_ADD)}
        />
      </Flex>
      <ProductsListFilter />

      <Flex marginTop={{ xs: 'space32' }}>
        <ProductsList pageSize={pageSize} type={type} search={search} />
      </Flex>
    </>
  );
};

export default Products;
