import { UiSchemaCustom } from '.';

const goodsAndDebts: UiSchemaCustom[] = [
  {
    'ui:order': ['WD_BIENS_EPOUX', 'BIEN'],
    WD_BIENS_EPOUX: {
      'ui:widget': 'radio',
    },
    BIEN: {
      items: {
        'ui:order': ['WD_TYPE', 'WD_OWNER', 'WD_ATTRIBUTION'],
        'ui:options': {
          fullWidth: true,
          withoutMargin: true,
        },
        WD_TYPE: {
          'ui:widget': 'select',
          'ui:placeholder': 'Choisir un type de bien',
        },
        WD_ATTRIBUTION: {
          'ui:widget': 'select',
          'ui:placeholder': 'Choisir une option',
        },
        WD_OWNER: {
          'ui:widget': 'select',
          'ui:placeholder': 'Choisir une option',
        },
      },
    },
  },
  {
    'ui:order': ['WD_PRETS_EN_COURS', 'PRETS', 'WD_DOSS_DETTES'],
    WD_PRETS_EN_COURS: {
      'ui:widget': 'radio',
    },
    PRETS: {
      items: {
        'ui:order': [
          'WD_TYPE',
          'NOM',
          'MONTANT',
          'RESTANT',
          'MENSUALITE',
          'TERME',
          'PAYEUR',
        ],
        'ui:options': {
          fullWidth: true,
          withoutMargin: true,
        },
        WD_TYPE: {
          'ui:widget': 'select',
          'ui:placeholder': 'Choisir un type de prêt',
        },
        NOM: {
          'ui:field': 'CustomField',
          'ui:placeholder': 'Société Générale',
        },
        MONTANT: {
          'ui:field': 'CustomCurrency',
          'ui:placeholder': '20 000',
        },
        RESTANT: {
          'ui:field': 'CustomCurrency',
          'ui:placeholder': '2 000',
        },
        MENSUALITE: {
          'ui:field': 'CustomCurrency',
          'ui:placeholder': '200',
        },
        TERME: {
          'ui:field': 'CustomDatepicker',
          'ui:placeholder': 'Choisir une date',
        },
        PAYEUR: {
          'ui:widget': 'select',
          'ui:placeholder': 'Choisir une option',
        },
      },
    },
    WD_DOSS_DETTES: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir une option',
    },
  },
  {
    'ui:order': ['WD_AUTRES_DETTES', 'AUTRES_DETTES'],
    WD_AUTRES_DETTES: {
      'ui:widget': 'radio',
    },
    AUTRES_DETTES: {
      items: {
        'ui:order': [
          'WD_TYPE',
          'WD_ORGANISME',
          'WD_MONTANT',
          'WD_MODALITES',
          'WD_DEBITEUR',
          'WD_PARTIE1',
          'WD_PARTIE2',
        ],
        'ui:options': {
          fullWidth: true,
          withoutMargin: true,
        },
        WD_TYPE: {
          'ui:widget': 'select',
          'ui:placeholder': 'Choisir un type de dette',
        },
        WD_ORGANISME: {
          'ui:field': 'CustomField',
          'ui:placeholder': 'Société Générale',
        },
        WD_MONTANT: {
          'ui:field': 'CustomCurrency',
          'ui:placeholder': '20 000',
        },
        WD_MODALITES: {
          'ui:field': 'CustomField',
          'ui:placeholder': 'Modalités',
        },
        WD_DEBITEUR: {
          'ui:widget': 'select',
          'ui:placeholder': 'Choisir une option',
        },
        WD_PARTIE1: {
          'ui:widget': 'select',
          'ui:placeholder': '60%',
        },
        WD_PARTIE2: {
          'ui:widget': 'select',
          'ui:placeholder': '40%',
        },
      },
    },
  },
  {
    'ui:order': ['WD_DETTE_ENTRE_EPOUX', 'WD_DETTES_EPOUX'],
    WD_DETTE_ENTRE_EPOUX: {
      'ui:widget': 'radio',
    },
    WD_DETTES_EPOUX: {
      items: {
        'ui:order': [
          'WD_MONTANT_DETTE',
          'WD_OBJET_DETTE',
          'WD_DETTE_CREANCIER',
        ],
        'ui:options': {
          fullWidth: true,
          withoutMargin: true,
        },
        WD_MONTANT_DETTE: {
          'ui:field': 'CustomCurrency',
          'ui:placeholder': '10 000',
        },
        WD_OBJET_DETTE: {
          'ui:field': 'CustomField',
          'ui:placeholder': 'Prêt immobilier',
        },
        WD_DETTE_CREANCIER: {
          'ui:widget': 'select',
          'ui:placeholder': 'Choisir une option',
        },
      },
    },
  },
  {
    'ui:order': ['WD_ACTES_DONATION_PRESENCE', 'WD_DONATIONS'],
    WD_ACTES_DONATION_PRESENCE: {
      'ui:widget': 'radio',
    },
    WD_DONATIONS: {
      items: {
        'ui:order': ['WD_ACTE_NOTARIE_PRESENCE', 'WD_ACTES_REVOCATION'],
        'ui:options': {
          fullWidth: true,
          withoutMargin: true,
        },

        WD_ACTES_REVOCATION: {
          'ui:widget': 'radio',
        },
        WD_ACTE_NOTARIE_PRESENCE: {
          'ui:widget': 'radio',
        },
      },
    },
  },
];

export default goodsAndDebts;
