import { createGlobalStyle } from 'styled-components';
import { theme } from 'theme';
import ValueRegular from 'assets/fonts/value-serif-regular.ttf';
import ValueBold from 'assets/fonts/value-serif-bold.ttf';
import ValueMedium from 'assets/fonts/value-serif-medium.ttf';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Value Serif';
    src: url(${ValueBold});
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Value Serif';
    src: url(${ValueMedium});
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Value Serif';
    src: url(${ValueRegular});
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  body {
    font-family: ${theme.fontFamily.inter};
  }

  body, html, #root {
    height: 100%;
    background-color: ${theme.colors.gray1};
  }

  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button {
    color: ${theme.colors.gray5};
    border: none;
    background-color: transparent;
    text-decoration: none;
    border-bottom: 1px solid ${theme.colors.gray5};
  }

  // Mui DatePicker styles must be global because picker is outside of current DOM
  .Mui-selected {
    background-color: ${theme.colors.salmon1} !important;
  }
  .MuiPaper-root {
    border-radius: ${theme.spacing.space16} !important;
    margin-top: ${theme.spacing.space16};
    box-shadow: 0px 4px 16px rgba(44, 41, 38, 0.08) !important;
  }

  .MuiPaper-root > * {
      font-family: ${theme.fontFamily.inter} !important;
  }

  .MuiCalendarPicker-root {
    font-family: ${theme.fontFamily.inter} !important;
  }

  #launcher {
    display: none;
  }

  :root {
      --rt-opacity: 1;
  }
`;
