import { Account, Form, Page404 } from 'pages/common';
// Pages
import {
  ClientAdministrativeDocuments,
  ClientAppointment,
  ClientConvention,
  ClientDocuments,
  ClientFinal,
  ClientFormalityNotary,
  ClientFormalityCivilStatus,
  ClientFormalityFinalSending,
  ClientForms,
  ClientHome,
  ClientConversationPage,
  ClientRegisteredLetters,
  ClientLawyerFees,
} from 'pages/client';
import { Redirect, Route, Switch } from 'react-router-dom';

import { FC } from 'react';
import { CrispProvider } from 'container/client';
import { Header } from 'container/common/Header/Header';
import Router from 'types/router.types';
import {
  ErrorPayment,
  PaymentResume,
  Quote,
  QuoteResumePage,
  SuccessfulPayment,
} from 'pages/public';
import styled from 'styled-components';
import { useViewport } from 'hooks/useViewport';
import { CustomerProvider } from './context';
import { PublicAppRoutes } from './PublicApp';

// Component

const Container = styled.div`
  padding-top: 70px;
`;

const SentryTestError = () => {
  throw new Error('Sentry Test 🐞');
  return null;
};

export enum ClientAppRoutes {
  HOME = '/',
  ERROR = '/error',
  FORMS = '/formulaires',
  FORM = '/formulaire/:id/:opportunityId',
  ACCOUNT = '/mon-compte',
  LAWYER_FEES = '/convention-honoraires',
  DOCUMENTS = '/documents',
  QUOTE = '/devis',
  QUOTE_RESUME = '/recapitulatif-devis/:id',
  PAYMENT_RESUME = '/recapitulatif-paiement/:id',
  ERROR_PAYMENT = '/attente-paiement',
  ADMINISTRATIVE_DOCUMENTS = '/administrative-documents',
  CONVENTION = '/convention',
  REGISTERED_LETTERS = '/lettres-recommandees',
  APPOINTMENT = '/rendez-vous',
  FORMALITY_NOTARY = '/formalites/notaire',
  FORMALITY_MARITAL_STATUS = '/formalites/etat-civil',
  FORMALITY_FINAL_SENDING = '/formalites/envoi-final',
  FINAL = '/final',
  SUCCESSFUL_PAYMENT = '/confirmation-paiement',
  MESSAGING = '/messagerie',
  SIGN_IN = '/connexion',
}

const clientRoutes: Record<ClientAppRoutes, Router> = {
  [ClientAppRoutes.SUCCESSFUL_PAYMENT]: {
    routeProps: {
      path: ClientAppRoutes.SUCCESSFUL_PAYMENT,
      exact: true,
      component: SuccessfulPayment,
    },
    isMobile: true,
  },
  [ClientAppRoutes.ERROR_PAYMENT]: {
    routeProps: {
      path: ClientAppRoutes.ERROR_PAYMENT,
      exact: true,
      component: ErrorPayment,
    },
    isMobile: true,
  },
  [ClientAppRoutes.HOME]: {
    routeProps: {
      path: ClientAppRoutes.HOME,
      exact: true,
      component: ClientHome,
    },
    isMobile: true,
  },
  [ClientAppRoutes.QUOTE]: {
    routeProps: {
      path: ClientAppRoutes.QUOTE,
      exact: true,
      render: Object.assign(() => <Quote />, { displayName: 'Quote' }),
    },
    isMobile: true,
  },
  [ClientAppRoutes.QUOTE_RESUME]: {
    routeProps: {
      path: ClientAppRoutes.QUOTE_RESUME,
      exact: true,
      render: Object.assign(() => <QuoteResumePage />, {
        displayName: 'QuoteResumePage',
      }),
    },
    isMobile: true,
  },
  [ClientAppRoutes.PAYMENT_RESUME]: {
    routeProps: {
      path: ClientAppRoutes.PAYMENT_RESUME,
      exact: true,
      render: Object.assign(() => <PaymentResume />, {
        displayName: 'PaymentResume',
      }),
    },
    isMobile: true,
  },
  [ClientAppRoutes.ERROR]: {
    routeProps: {
      path: ClientAppRoutes.ERROR,
      exact: true,
      component: SentryTestError,
    },
    isMobile: true,
  },
  [ClientAppRoutes.FORMS]: {
    routeProps: {
      path: ClientAppRoutes.FORMS,
      exact: true,
      component: ClientForms,
    },
    isMobile: true,
  },
  [ClientAppRoutes.FORM]: {
    routeProps: {
      path: ClientAppRoutes.FORM,
      exact: true,
      component: Form,
    },
    isMobile: true,
  },
  [ClientAppRoutes.ACCOUNT]: {
    routeProps: {
      path: ClientAppRoutes.ACCOUNT,
      exact: true,
      component: Account,
    },
    isMobile: true,
  },
  [ClientAppRoutes.DOCUMENTS]: {
    routeProps: {
      path: ClientAppRoutes.DOCUMENTS,
      exact: true,
      component: ClientDocuments,
    },
    isMobile: true,
  },
  [ClientAppRoutes.ADMINISTRATIVE_DOCUMENTS]: {
    routeProps: {
      path: ClientAppRoutes.ADMINISTRATIVE_DOCUMENTS,
      exact: true,
      component: ClientAdministrativeDocuments,
    },
    isMobile: true,
  },
  [ClientAppRoutes.LAWYER_FEES]: {
    routeProps: {
      path: ClientAppRoutes.LAWYER_FEES,
      exact: true,
      component: ClientLawyerFees,
    },
    isMobile: true,
  },
  [ClientAppRoutes.CONVENTION]: {
    routeProps: {
      path: ClientAppRoutes.CONVENTION,
      exact: true,
      component: ClientConvention,
    },
  },
  [ClientAppRoutes.REGISTERED_LETTERS]: {
    routeProps: {
      path: ClientAppRoutes.REGISTERED_LETTERS,
      exact: true,
      component: ClientRegisteredLetters,
    },
  },
  [ClientAppRoutes.APPOINTMENT]: {
    routeProps: {
      path: ClientAppRoutes.APPOINTMENT,
      exact: true,
      component: ClientAppointment,
    },
  },
  [ClientAppRoutes.FORMALITY_NOTARY]: {
    routeProps: {
      path: ClientAppRoutes.FORMALITY_NOTARY,
      exact: true,
      component: ClientFormalityNotary,
    },
  },
  [ClientAppRoutes.FORMALITY_MARITAL_STATUS]: {
    routeProps: {
      path: ClientAppRoutes.FORMALITY_MARITAL_STATUS,
      exact: true,
      component: ClientFormalityCivilStatus,
    },
  },
  [ClientAppRoutes.FORMALITY_FINAL_SENDING]: {
    routeProps: {
      path: ClientAppRoutes.FORMALITY_FINAL_SENDING,
      exact: true,
      component: ClientFormalityFinalSending,
    },
  },
  [ClientAppRoutes.FINAL]: {
    routeProps: {
      path: ClientAppRoutes.FINAL,
      exact: true,
      component: ClientFinal,
    },
  },
  [ClientAppRoutes.MESSAGING]: {
    routeProps: {
      path: ClientAppRoutes.MESSAGING,
      exact: true,
      component: ClientConversationPage,
    },
    isMobile: true,
  },
  [PublicAppRoutes.SIGN_IN]: {
    routeProps: {
      path: PublicAppRoutes.SIGN_IN,
      exact: true,
      component: () => <Redirect to={ClientAppRoutes.HOME} />,
    },
  },
};

const ClientApp: FC = () => {
  const { isMobile } = useViewport();

  return (
    <CustomerProvider>
      <CrispProvider>
        <Header />
        <Container>
          <Switch>
            {Object.values(clientRoutes).map((route) => {
              if (!isMobile || route.isMobile) {
                return (
                  <Route
                    key={`clientRoute-${route.routeProps.path}`}
                    {...route.routeProps}
                  />
                );
              }
            })}
            {isMobile && <Redirect to={ClientAppRoutes.HOME} />}
            <Route component={Page404} />
          </Switch>
        </Container>
      </CrispProvider>
    </CustomerProvider>
  );
};

export default ClientApp;
