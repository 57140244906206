import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Components
import { Modal } from './Modal';
import { Flex, Line, Text } from 'components/Layout';
import { theme } from 'theme';
import { Button, IconButton } from 'components/Buttons';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { TextArea } from 'components/FormTemplate/Fields/TextArea';
import { SendNotifactionParams } from 'container/common/Contact/Contact';

interface ContactModalProps {
  onClose: () => void;
  opened?: boolean;
  onSubmit: (data: SendNotifactionParams) => void;
  title: string;
  message?: string | null;
  subject?: string | null;
  hideClose?: boolean;
}

const ContactModal: FC<ContactModalProps> = ({
  onClose,
  opened,
  onSubmit,
  title,
  message,
  subject,
  hideClose = false,
}) => {
  const { t } = useTranslation();
  const { register, reset, handleSubmit } = useForm<SendNotifactionParams>();

  useEffect(() => {
    reset(); // clean form when opened value change
  }, [opened]);

  return (
    <Modal opened={opened} onClose={onClose} width="720px">
      <Flex marginBottom={{ xs: 'space32' }} alignItems="center">
        <IconButton
          iconName="Message"
          color={theme.colors.salmon1}
          backgroundColor={theme.colors.salmon3}
          rounded
        />
        <Flex alignItems="center">
          <Text
            content={title}
            fontStyle={'heading5'}
            marginLeft={{ xs: 'space16' }}
            weight="bold"
          />
        </Flex>
        {!hideClose && (
          <Flex marginLeft={{ xs: 'auto' }} marginRight={{ xs: 'none' }}>
            <Button
              iconLeft={{ name: 'close' }}
              content={t('formality_letters.address.modal.close')}
              onClick={onClose}
              size="small"
            />
          </Flex>
        )}
      </Flex>
      <Flex marginBottom={{ xs: 'space32' }}>
        <Line />
      </Flex>
      <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <Flex marginBottom={{ xs: 'space16' }}>
          <InputText
            {...register('subject', {
              required: t('error.form.required').toString(),
              value: subject || '',
            })}
            type="text"
            label={t('subject')}
            placeholder={t('subject')}
            width="100%"
            required
          />
        </Flex>
        <Flex marginBottom={{ xs: 'space16' }}>
          <TextArea
            {...register('message', {
              required: t('error.form.required').toString(),
              value: message || '',
            })}
            type="text"
            label={t('message')}
            placeholder={t('message')}
            width="100%"
            required
          />
        </Flex>

        <Flex marginTop={{ xs: 'space32' }}>
          <Button type="submit" content={t('send')} primary />
        </Flex>
      </form>
    </Modal>
  );
};

export default ContactModal;
