import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Container
import { UsersList, UsersListFilters } from 'container/admin';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { Button } from 'components/Buttons/Button';

// Routes
import { AdminAppRoutes } from 'AdminApp';
import { useApi } from '../../hooks/useApi';
import { Department } from '../../types/resources';
import { useQueryParams } from '../../hooks/useQueryParams';

const Users: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const query = useQueryParams();
  const pageSize = 10;
  const role = query.get('role');
  const department = query.get('department');
  const search = query.get('search');
  const { execute: getDepartments, state: getDepartmentsState } = useApi<
    Department[]
  >('/departments', { cache: true });
  const departments = getDepartmentsState.data?.value;

  useEffect(() => {
    getDepartments();
  }, []);

  return (
    <>
      <Flex justify="between">
        <Text content={t('user.users')} fontStyle="heading2" />
        <Button
          content={t('user.add_new_user')}
          iconLeft={{ name: 'Plus' }}
          onClick={() => history.push(AdminAppRoutes.USER_ADD)}
        />
      </Flex>
      {departments && <UsersListFilters departments={departments} />}
      {departments && (
        <Flex marginTop={{ xs: 'space32' }}>
          <UsersList
            pageSize={pageSize}
            search={search}
            role={role}
            department={department}
            departments={departments}
          />
        </Flex>
      )}
    </>
  );
};

export default Users;
