import { Dispatch, FC, forwardRef, SetStateAction, ForwardedRef } from 'react';
import styled from 'styled-components';

// Components
import { theme } from 'theme';
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import checkIcon from '../../../assets/svg/check.svg';
import { Alignments } from 'theme/styles/flex';
import { FontStyles } from 'theme/styles/fonts';
import { Weights } from 'theme/styles/size';

interface StyledCheckboxProps {
  ref: ForwardedRef<HTMLInputElement>;
  borderColor: string;
  rounded?: boolean;
  checkPoint?: boolean;
  small?: boolean;
}

const StyledCheckbox = styled.input<StyledCheckboxProps>`
  appearance: none;
  border: 1px solid ${(props) => props.borderColor};
  height: ${(props) => (props?.small ? '20px' : '24px')};
  width: ${(props) => (props?.small ? '20px' : '24px')};
  border-radius: ${(props) => (props?.rounded ? '100%' : '5px')};
  min-width: ${(props) => (props?.small ? '20px' : '24px')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.2s ease-in-out;

  &:checked {
    position: relative;
    background-color: ${theme.colors.green1};
    border: 1px solid ${theme.colors.green1};
    transition: background-color 0.2s ease-in-out;

    &:before {
      height: 100%;
      margin-top: 1px;
      content: url(${checkIcon});
      ${(props) =>
        props?.checkPoint &&
        `
        content: '';
        width: 7px;
        height: 7px;
        background-color: ${theme.colors.white};
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        margin-top: 0px !important;
      `}
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export interface InputCheckboxProps {
  label?: string;
  bold?: boolean;
  disabled?: boolean;
  checked?: boolean;
  setChecked?: Dispatch<SetStateAction<boolean>>;
  error?: boolean;
  onChange?: (val: any) => void;
  textProps?: Record<string, any>;
  align?: Alignments;
  justify?: Alignments;
  fontStyle?: FontStyles;
  fontWeight?: Weights;
  rounded?: boolean;
  dangerouslySetInnerHTML?: boolean;
  checkPoint?: boolean;
  small?: boolean;
}

const InputCheckbox: FC<InputCheckboxProps> = forwardRef<
  HTMLInputElement,
  InputCheckboxProps
>(
  (
    {
      label,
      checked,
      setChecked,
      disabled = false,
      children,
      error,
      onChange,
      textProps,
      align = 'start',
      justify = 'between',
      fontStyle,
      fontWeight,
      rounded = false,
      dangerouslySetInnerHTML = false,
      checkPoint = false,
      small = false,
    },
    ref,
  ) => {
    const borderColor = error ? theme.colors.red1 : theme.colors.gray4;
    const toggleChecked = () => {
      if (setChecked) {
        setChecked(!checked);
      }
      if (onChange) {
        onChange(!checked);
      }
    };
    return (
      <Flex justify={justify} width="100%">
        <Flex alignItems={align}>
          <StyledCheckbox
            type="checkbox"
            ref={ref}
            checked={checked}
            disabled={disabled}
            borderColor={borderColor}
            onChange={toggleChecked}
            rounded={rounded}
            checkPoint={checkPoint}
            small={small}
          />
          {label && !dangerouslySetInnerHTML && (
            <Text
              content={label}
              fontStyle={fontStyle || 'body1'}
              marginLeft={{ xs: 'space16' }}
              weight={fontWeight || 'regular'}
              onClick={toggleChecked}
              {...textProps}
            />
          )}
          {label && dangerouslySetInnerHTML && (
            <Text
              dangerouslySetInnerHTML={{ __html: label }}
              fontStyle={fontStyle || 'body1'}
              marginLeft={{ xs: 'space16' }}
              weight={fontWeight || 'regular'}
              onClick={toggleChecked}
              {...textProps}
            />
          )}
        </Flex>
        {children}
      </Flex>
    );
  },
);

InputCheckbox.displayName = 'InputCheckbox';

export default InputCheckbox;
