import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Components
import { ClientCard, ProgressCard } from 'components/Cards';
import { Flex } from 'components/Layout';

import { getStepPercentage } from 'utils/math';
import { ButtonProps } from 'components/Buttons/Button';
import { md } from 'theme/styles/mediaQueries';
import {
  Form,
  FormStatus,
  ProcedureFull,
  ProcedureStepFull,
} from '../../../types/resources';
import { useAuth } from '../../../hooks/useAuth';

interface FormsProgressionProps {
  procedure: ProcedureFull;
  forms: Form[];
  step: ProcedureStepFull;
  loading: boolean;
  onValidate: () => void;
  onUnlock: () => void;
}

const Wrapper = styled(ClientCard)`
  height: auto;

  ${md(`
    position: sticky;
    top: 102px;
  `)}
`;

const FormsProgression: FC<FormsProgressionProps> = ({
  procedure,
  forms,
  step,
  loading,
  onValidate,
  onUnlock,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const countAllComments = 0;
  const validations = step.validations;
  const spouse1Validation = validations.find(
    (v) => v.user_id === procedure.spouse1_id,
  );
  const spouse2Validation = validations.find(
    (v) => v.user_id === procedure.spouse2_id,
  );
  const myValidation = validations.find((v) => v.user_id === user?.id);

  // TODO
  // const countAllComments = useMemo(() => {
  //   return (
  //     forms.reduce((count, { countComments }) => (count += countComments), 0) ||
  //     0
  //   );
  // }, [forms]);

  const getPercentage = () => {
    if (!forms) return 0;
    const totalForms = forms.length;
    const filledForms = forms.filter((form) => {
      return [
        FormStatus.COMPLETED,
        FormStatus.IN_REVIEW,
        FormStatus.VALIDATED,
      ].includes(form.status);
    }).length;
    return Number(getStepPercentage(filledForms, totalForms).toFixed(0));
  };

  const getDescription = (percentage: number) => {
    if (percentage !== 100) {
      return t('forms_opportunity.progress.card_description_default');
    } else {
      if (spouse2Validation && spouse1Validation) {
        return t(
          'forms_opportunity.progress.card_description_with_all_validation',
        );
      } else if (myValidation) {
        return t(
          'forms_opportunity.progress.card_description_with_my_validation',
        );
      } else if (countAllComments > 0) {
        return t('forms_opportunity.progress.card_description_with_comments');
      } else if (spouse2Validation || spouse1Validation) {
        return t(
          'forms_opportunity.progress.card_description_with_one_validation',
        );
      }

      return t(
        'forms_opportunity.progress.card_description_without_validation',
      );
    }
  };
  const percentage = getPercentage();
  const complete = percentage === 100;

  /*
   * Je cache le button si :
   * j'ai validé ou
   * que personne n'a validé
   */

  const spousesUnvalidated = !spouse2Validation && !spouse1Validation;
  const hideUnlockButton = myValidation || spousesUnvalidated;

  const cardButton: Array<ButtonProps | null> = [
    {
      content: countAllComments
        ? t('forms_opportunity.progress.valid_comments')
        : t('forms_opportunity.progress.valid'),
      primary: complete,
      fullWidth: true,
      disabled: !complete || !!myValidation,
      $loading: loading,
      onClick: onValidate,
    },
    hideUnlockButton
      ? null
      : {
          content: t('forms_opportunity.progress.unlock'),
          primary: false,
          fullWidth: true,
          $loading: loading,
          onClick: onUnlock,
        },
  ];

  return (
    <Wrapper
      iconName="Document"
      title={t('forms_opportunity.progress.title')}
      buttonsDirections={{ xs: 'column' }}
      buttons={cardButton}
    >
      <Flex>
        <ProgressCard
          title={t('forms_opportunity.progress.card_title')}
          description={getDescription(percentage)}
          percentage={percentage}
          showDescriptionOnMobile
        />
      </Flex>
    </Wrapper>
  );
};

export default FormsProgression;
