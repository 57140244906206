import { FC } from 'react';

// Components
import { theme } from 'theme';
import { Flex } from 'components/Layout/Flex';
import { IconButton } from 'components/Buttons/IconButton';
import { InputOneNumber } from 'components/FormTemplate/Fields/InputOneNumber';

export interface InputPlusMinusProps {
  value?: number;
  removeValue: () => void;
  addValue: () => void;
  minItems?: number;
  maxItems?: number;
}

export const InputPlusMinus: FC<InputPlusMinusProps> = ({
  value = 0,
  removeValue,
  addValue,
  minItems = 0,
  maxItems,
}) => {
  const disableRemove = value === minItems;
  const disableNext = value === maxItems;

  return (
    <Flex alignItems="center">
      <IconButton
        iconName="minus"
        color={theme.colors.white}
        backgroundColor={
          disableRemove ? theme.colors.green4 : theme.colors.green1
        }
        onClick={() => !disableRemove && removeValue()}
        rounded
      />
      <Flex marginRight={{ xs: 'space24' }} marginLeft={{ xs: 'space24' }}>
        <InputOneNumber value={value} />
      </Flex>
      <IconButton
        iconName="plus"
        color={theme.colors.white}
        backgroundColor={
          disableNext ? theme.colors.green4 : theme.colors.green1
        }
        onClick={() => !disableNext && addValue()}
        rounded
      />
    </Flex>
  );
};
