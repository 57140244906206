import { Flex } from 'components/Layout';
import { ContactModal } from 'components/Modal';
import { useQueryParams } from 'hooks/useQueryParams';
import { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Toaster from 'components/Toaster/Toaster';
import { env } from '../../../config/env';

export interface SendNotifactionParams {
  message: string;
  subject: string;
}

const Contact: FC = () => {
  const history = useHistory();
  const query = useQueryParams();
  const location = useLocation();
  const contactOpened = query.get('contact');
  const emailTo = query.get('emailTo');
  const opportunityId = query.get('opportunityId');
  const message = query.get('message');
  const subject = query.get('subject');
  const hideClose = query.get('hideClose') === 'true' ? true : false;
  const [showToasterSuccess, toggleToasterSuccess] = useState(false);
  const [showToasterFail, toggleToasterFail] = useState(false);

  const onClose = () => {
    if (location.key) {
      return history.goBack();
    }
    return history.push({
      pathname: location.pathname,
    });
  };

  const onSubmit = async ({ message, subject }: SendNotifactionParams) => {
    fetch(`${env.api.url}/notification/sendNotification`, {
      method: 'POST',
      body: JSON.stringify({
        message,
        subject,
        emailTo: emailTo && decodeURIComponent(emailTo),
        opportunityId,
      }),
    }).then(async (response) => {
      if (response.ok && response.status === 201) {
        toggleToasterSuccess(true);
        return onClose();
      } else {
        toggleToasterFail(true);
      }
    });
  };

  return (
    <>
      <Flex>
        <ContactModal
          opened={!!contactOpened}
          onClose={hideClose ? () => {} : onClose}
          title="Formulaire de contact"
          onSubmit={onSubmit}
          message={message}
          subject={subject}
          hideClose={hideClose}
        />
      </Flex>
      <Toaster
        active={showToasterSuccess}
        type="success"
        message={'Message envoyé avec succès'}
      />
      <Toaster
        active={showToasterFail}
        type="error"
        message={'Une erreur serveur est survenue. Veuillez réessayer'}
      />
    </>
  );
};

export default Contact;
