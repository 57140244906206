import React, {
  Key,
  ReactNode,
  CSSProperties,
  MouseEventHandler,
  forwardRef,
  ForwardedRef,
} from 'react';
import { Alignments, Positions } from 'theme/styles/flex';
import styled from 'styled-components';
import { theme } from 'theme';
import {
  BreakpointsSpacing,
  ConvertedSpacing,
  printBreakpointRules,
  BreakpointsDirection,
  ConvertedDirection,
  convertDirection,
  convertSpacing,
} from 'theme/styles/responsive';
import { lg, md } from 'theme/styles/mediaQueries';
import cn from 'classnames';

// Flex props
export interface FlexProps {
  id?: string;
  key?: Key;
  direction?: BreakpointsDirection;
  alignItems?: Alignments;
  alignSelf?: Alignments;
  alignContent?: Alignments;
  justify?: Alignments;
  flex?: number;
  flexGrow?: number;
  flexBasis?: string;
  wrap?: string;
  expand?: boolean;
  position?: Positions;
  desktopOnly?: boolean;
  mobileOnly?: boolean;
  lgOnly?: boolean;
  children?: ReactNode;
  paddingBottom?: BreakpointsSpacing;
  paddingTop?: BreakpointsSpacing;
  paddingLeft?: BreakpointsSpacing;
  paddingRight?: BreakpointsSpacing;
  marginBottom?: BreakpointsSpacing;
  marginTop?: BreakpointsSpacing;
  marginLeft?: BreakpointsSpacing;
  marginRight?: BreakpointsSpacing;
  className?: string;
  width?: string;
  height?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  cursor?: string;
  style?: CSSProperties;
}

// Styled props
interface StyledFlexProps {
  ref?: ForwardedRef<HTMLDivElement>;
  id?: string;
  direction: ConvertedDirection;
  alignItems?: string;
  alignSelf?: string;
  alignContent?: string;
  justify?: string;
  position: string;
  flex?: number;
  flexGrow?: number;
  flexBasis?: string;
  wrap?: string;
  expand?: boolean;
  desktopOnly?: boolean;
  mobileOnly?: boolean;
  lgOnly?: boolean;
  paddingBottom: ConvertedSpacing;
  paddingTop: ConvertedSpacing;
  paddingLeft: ConvertedSpacing;
  paddingRight: ConvertedSpacing;
  marginBottom: ConvertedSpacing;
  marginTop: ConvertedSpacing;
  marginLeft: ConvertedSpacing;
  marginRight: ConvertedSpacing;
  width?: string;
  maxWidth?: string;
  height?: string;
  maxHeight?: string;
  cursor?: string;
}

// Styled component
const StyledFlex = styled.div<StyledFlexProps>`
  display: ${(props) => (props.desktopOnly || props.lgOnly ? 'none' : 'flex')};
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.alignItems};
  align-self: ${(props) => props.alignSelf};
  align-content: ${(props) => props.alignContent};
  flex-wrap: ${(props) => props.wrap};
  position: ${(props) => props.position};
  ${(props) => typeof props.flex === 'number' && `flex: ${props.flex}`};
  ${(props) =>
    typeof props.flexGrow === 'number' && `flex-grow: ${props.flexGrow}`};
  flex-basis: ${(props) => props.flexBasis};
  ${(props) => printBreakpointRules(props.paddingTop)};
  ${(props) => printBreakpointRules(props.paddingBottom)};
  ${(props) => printBreakpointRules(props.paddingLeft)};
  ${(props) => printBreakpointRules(props.paddingRight)};
  ${(props) => printBreakpointRules(props.marginTop)};
  ${(props) => printBreakpointRules(props.marginBottom)};
  ${(props) => printBreakpointRules(props.marginLeft)};
  ${(props) => printBreakpointRules(props.marginRight)};
  ${(props) => printBreakpointRules(props.direction)};
  ${(props) => (props.width ? `width: ${props.width};` : 'none')};
  ${(props) => (props.height ? `height: ${props.height};` : 'none')};
  ${(props) => (props.cursor ? `cursor: ${props.cursor};` : 'initial')};
  ${(props) => props.expand && `width: 100%;`}
  ${(props) =>
    md(`display: ${props.mobileOnly || props.lgOnly ? 'none' : 'flex'};`)}
  ${(props) => lg(`display: ${props.mobileOnly ? 'none' : 'flex'};`)}
`;

// Flex component
export const Flex = forwardRef<any, FlexProps>(function Flex(
  {
    id,
    direction,
    justify,
    alignItems,
    alignContent,
    alignSelf,
    flex,
    flexBasis,
    flexGrow,
    wrap,
    expand,
    position,
    desktopOnly,
    mobileOnly,
    lgOnly,
    children,
    paddingBottom,
    paddingTop,
    paddingLeft,
    paddingRight,
    marginBottom,
    marginTop,
    marginLeft,
    marginRight,
    className = '',
    width = '',
    height = '',
    cursor,
    style,
    onClick,
  },
  ref,
) {
  const p = {
    ...(alignItems ? { alignItems: theme.alignments[alignItems] } : undefined),
    ...(alignContent
      ? { alignContent: theme.alignments[alignContent] }
      : undefined),
    ...(alignSelf ? { alignSelf: theme.alignments[alignSelf] } : undefined),
    ...(justify ? { justify: theme.alignments[justify] } : undefined),
  };

  return (
    <StyledFlex
      {...p}
      ref={ref}
      width={width}
      height={height}
      cursor={cursor}
      onClick={onClick}
      id={id}
      direction={convertDirection('flex-direction', direction)}
      flex={flex}
      flexBasis={flexBasis}
      flexGrow={flexGrow}
      wrap={wrap}
      expand={expand}
      position={theme.positions[position || 'relative']}
      desktopOnly={desktopOnly}
      mobileOnly={mobileOnly}
      lgOnly={lgOnly}
      paddingTop={convertSpacing('padding-top', paddingTop)}
      paddingBottom={convertSpacing('padding-bottom', paddingBottom)}
      paddingRight={convertSpacing('padding-right', paddingRight)}
      paddingLeft={convertSpacing('padding-left', paddingLeft)}
      marginTop={convertSpacing('margin-top', marginTop)}
      marginBottom={convertSpacing('margin-bottom', marginBottom)}
      marginRight={convertSpacing('margin-right', marginRight)}
      marginLeft={convertSpacing('margin-left', marginLeft)}
      className={cn({
        [className]: className,
      })}
      style={style}
    >
      {children}
    </StyledFlex>
  );
});
