import React, { FC, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { addDays, differenceInDays } from 'date-fns';

// Components
import { theme } from 'theme';
import { Flex, Line, Requires, Text } from 'components/Layout';
import { IconButton } from 'components/Buttons/IconButton';
import { Card } from 'components/Cards';
import CircleProgress from 'components/Progress/CircleProgress';
import { Step, Stepper } from 'components/Stepper';

// Container
import SpecificationCardList, {
  Specification,
} from 'container/client/ClientHome/SpecificationCardList';
import { ClientAppRoutes } from 'ClientApp';
import { Ghost } from 'components/Loading';
import { getHideByStatus } from 'utils/status';
import { Button } from 'components/Buttons';
import NotificationButton from 'container/client/ClientHome/NotificationButton/NotificationButton';
import {
  Conversation,
  EstimateFull,
  ProcedureFull,
  ProcedureSpouse,
  ProcedureStepStatus,
  ProcedureStepType,
} from '../../../types/resources';
import { useAuth } from '../../../hooks/useAuth';
import { getRouteWithParams } from '../../../utils/router';
import { PublicAppRoutes } from '../../../PublicApp';
import { env } from '../../../config/env';

const FlexPadding = styled(Flex)`
  padding: ${theme.spacing.space24};
`;

interface StepOpportunityProps {
  procedure: ProcedureFull;
  estimate?: EstimateFull;
  spouse?: ProcedureSpouse;
  conversations: Conversation[];
  hasToPay?: boolean; // (only on client app)
}

const StepperOpportunity: FC<StepOpportunityProps> = ({
  procedure,
  estimate,
  spouse,
  conversations,
  hasToPay,
}) => {
  const { t } = useTranslation();
  const { isCustomer } = useAuth();
  const history = useHistory();

  const currentStep = procedure.steps.find(
    (s) => s.status === ProcedureStepStatus.IN_PROGRESS,
  );

  const spouseLeadConversation = conversations.find(
    (c) => c.spouse_id === procedure.spouse1_id,
  );
  const spouseFollowerConversation = conversations.find(
    (c) => c.spouse_id === procedure.spouse2_id,
  );
  const groupedConversation = conversations.find((c) => c.spouse_id === null);

  const onClickContactUser = (conversationId: string) => {
    history.push({
      pathname: `${location.pathname}/messagerie`,
      search: `?conversation=${conversationId}`,
    });
  };

  if (!procedure)
    return <Ghost width="100%" height={563} shape="rect" rx={24} />;

  const nbAdult = parseInt(`${estimate?.adult_children}`) ?? 0;
  const nbYoung = parseInt(`${estimate?.minor_children}`) ?? 0;
  const totalChildren = nbAdult + nbYoung;

  const getOnClickStep = (step: ProcedureStepType) => {
    switch (step) {
      case ProcedureStepType.FORM:
        return () => history.push(ClientAppRoutes.FORMS);
      case ProcedureStepType.DOCUMENT:
        return () => history.push(ClientAppRoutes.DOCUMENTS);
      case ProcedureStepType.REVIEW_AGREEMENT:
        return () => history.push(ClientAppRoutes.CONVENTION);
      case ProcedureStepType.AGREEMENT_SENT:
        return () => history.push(ClientAppRoutes.REGISTERED_LETTERS);
      case ProcedureStepType.SIGNATURE:
        return () => history.push(ClientAppRoutes.APPOINTMENT);
      case ProcedureStepType.ARCHIVE_SIGN:
        return () => history.push(ClientAppRoutes.ADMINISTRATIVE_DOCUMENTS);
      case ProcedureStepType.NOTARY:
        return () => history.push(ClientAppRoutes.FORMALITY_NOTARY);
      case ProcedureStepType.MARITAL_STATUS:
        return () => history.push(ClientAppRoutes.FORMALITY_MARITAL_STATUS);
      case ProcedureStepType.FINAL_SENDING:
        return () => history.push(ClientAppRoutes.FORMALITY_FINAL_SENDING);
      default:
        return () => {};
    }
  };

  const steps: Step[] = Object.values(ProcedureStepType).map((step) => {
    const customerCaseStep = procedure.steps.find((s) => s.step === step);
    const status = customerCaseStep?.status;
    let finalStatus = status;

    const title = !isCustomer
      ? t(`opportunity.steps.${step}`)
      : t(`opportunity.steps_client.${step}`);

    let displayedStatus = status
      ? t(`opportunity.status.${status}`)
      : t(`opportunity.status.UNKNOWN`);

    // specific case for the step "signature"
    if (step === ProcedureStepType.SIGNATURE) {
      if (status === ProcedureStepStatus.IN_REVIEW) {
        finalStatus = ProcedureStepStatus.IN_PROGRESS;
        displayedStatus = t(
          `opportunity.status.${ProcedureStepStatus.IN_PROGRESS}`,
        );
      }
    }

    return {
      title: title,
      active:
        status !== undefined &&
        [
          ProcedureStepStatus.IN_PROGRESS,
          ProcedureStepStatus.IN_REVIEW,
          ProcedureStepStatus.VALIDATED,
          ProcedureStepStatus.LOCKED,
        ].includes(status),
      hideButton: status !== undefined && getHideByStatus(step, status),
      isDone: status === ProcedureStepStatus.VALIDATED,
      status: finalStatus,
      displayedStatus,
      onClick: getOnClickStep(step),
      updated_at: customerCaseStep?.updated_at,
    };
  });

  const titleKey = !isCustomer
    ? 'opportunity.folder_status_no_ref'
    : 'opportunity.folder_status';

  const specifications: Specification[] = [
    {
      icon: 'Ticket',
      title: t('opportunity.package'),
      value: estimate?.plan.name || '',
      description: estimate?.plan.description || '',
      hasInfo: true,
    },
    {
      icon: 'Document',
      title: t('opportunity.option_chosen', {
        count: estimate?.options?.length,
      }),
      value:
        estimate?.options
          ?.map((option) => t(`enums:${option.code}`))
          .join(' et ') || t('opportunity.no_option'),
      description: estimate?.options
        ?.map((option) => option.description)
        .join('\n'),
      hasInfo: !!estimate?.options?.length,
    },
    {
      icon: 'People',
      title: t('opportunity.children'),
      value:
        totalChildren > 0
          ? t('opportunity.nb_child_plural', {
              nbAdult: nbAdult || 0,
              nbYoung: nbYoung || 0,
            })
          : t('opportunity.nb_child'),
    },
    {
      icon: 'Home',
      title: t('opportunity.properties'),
      value: estimate?.has_real_estate
        ? t('opportunity.have_properties')
        : t('opportunity.no_properties'),
    },
  ];

  const getPercentage = () => {
    const totalSteps = 12;
    const validStepsStatuses = [
      ProcedureStepStatus.IN_REVIEW,
      ProcedureStepStatus.VALIDATED,
    ];
    const validSteps = procedure.steps.filter((s) =>
      validStepsStatuses.includes(s.status),
    );
    const isProcedureClosed = validSteps.find(
      (s) => s.step === ProcedureStepType.CLOSED,
    );

    const percentage = isProcedureClosed
      ? 100
      : (validSteps.length / totalSteps) * 100;
    return Math.floor(percentage);
  };
  const closeStep = procedure.steps.find(
    (step) =>
      step.step === ProcedureStepType.CLOSED &&
      step.status === ProcedureStepStatus.VALIDATED,
  );
  const getDistance = () => {
    if (!closeStep) return 0;

    const difference = differenceInDays(
      addDays(
        new Date(closeStep?.updated_at),
        Number(env.procedure.deletionPeriod),
      ),
      new Date(),
    );

    return Math.max(0, difference);
  };
  const daysBeforeDeletion = getDistance();

  const goToLawyerFees = () => history.push(ClientAppRoutes.LAWYER_FEES);
  const goToEstimateSummary = useCallback(() => {
    if (estimate) {
      history.push(
        getRouteWithParams(PublicAppRoutes.QUOTE_RESUME, {
          id: estimate.id,
        }),
      );
    }
  }, [estimate]);

  const shouldSignLawyerFee =
    spouse?.lawyer_id && !spouse.signed_lawyer_fees_id;
  const showSignLawyerFeesButton =
    !closeStep && isCustomer && shouldSignLawyerFee;
  const showPaymentButton = !closeStep && isCustomer && hasToPay;

  return (
    <>
      <Card padding="0">
        <FlexPadding justify="between">
          <Flex
            direction={{ xs: 'column' }}
            style={{ gap: theme.spacing.space24 }}
          >
            <Flex>
              <IconButton
                iconName="Folder"
                color={theme.colors.salmon1}
                backgroundColor={theme.colors.salmon3}
                rounded
                stroke="regular"
              />
              <Flex alignItems="center">
                <Text
                  content={t(titleKey, {
                    reference: procedure.reference,
                  })}
                  fontStyle={'heading5'}
                  marginLeft={{ xs: 'space16' }}
                  marginRight={{ xs: 'space16' }}
                />
              </Flex>
            </Flex>
            {isCustomer ? (
              <Flex>
                <NotificationButton procedure={procedure} />

                {showPaymentButton ? (
                  <Flex alignItems="center" height="100%">
                    <Button
                      primary
                      onClick={goToEstimateSummary}
                      iconLeft={{ name: 'Wallet' }}
                      size={'small'}
                    >
                      <Text
                        fontStyle={'body2'}
                        color={theme.colors.white}
                        marginLeft={{ xs: 'space16' }}
                        weight={'bold'}
                      >
                        <Trans i18nKey={'estimate.pay'} />
                      </Text>
                    </Button>
                  </Flex>
                ) : null}

                {!showPaymentButton && showSignLawyerFeesButton ? (
                  <Flex alignItems="center" height="100%">
                    <Button
                      primary
                      onClick={goToLawyerFees}
                      iconLeft={{ name: 'EditSquare' }}
                      size={'small'}
                    >
                      <Text
                        fontStyle={'body2'}
                        color={theme.colors.white}
                        marginLeft={{ xs: 'space16' }}
                        weight={'bold'}
                      >
                        <Trans i18nKey={'lawyer_agreement.sign'} />
                      </Text>
                    </Button>
                  </Flex>
                ) : null}
              </Flex>
            ) : (
              <Flex>
                {spouseLeadConversation?.id && (
                  <Button
                    content={`Contacter ${procedure.spouse1?.first_name}`}
                    iconLeft={{
                      name: 'Chat',
                      size: 'small',
                      notification:
                        spouseLeadConversation.unprocessed_messages > 0
                          ? 'md'
                          : undefined,
                    }}
                    primary={spouseLeadConversation.unprocessed_messages > 0}
                    size="small"
                    onClick={() => {
                      onClickContactUser(spouseLeadConversation.id);
                    }}
                  />
                )}
                {spouseFollowerConversation?.id && (
                  <Button
                    content={`Contacter ${procedure.spouse2?.first_name}`}
                    iconLeft={{
                      name: 'Chat',
                      size: 'small',
                      notification:
                        spouseFollowerConversation.unprocessed_messages > 0
                          ? 'md'
                          : undefined,
                    }}
                    primary={
                      spouseFollowerConversation.unprocessed_messages > 0
                    }
                    marginLeft={{ xs: 'space16' }}
                    size="small"
                    onClick={() => {
                      onClickContactUser(spouseFollowerConversation.id);
                    }}
                  />
                )}
                {groupedConversation?.id && (
                  <Button
                    content={`Contacter les deux conjoints`}
                    iconLeft={{
                      name: 'Chat',
                      size: 'small',
                      notification:
                        groupedConversation.unprocessed_messages > 0
                          ? 'md'
                          : undefined,
                    }}
                    primary={groupedConversation.unprocessed_messages > 0}
                    marginLeft={{ xs: 'space16' }}
                    size="small"
                    onClick={() => {
                      onClickContactUser(groupedConversation.id);
                    }}
                  />
                )}
                {/*{data?.opportunity?.groupedConversationId && (*/}
                {/*  <Button*/}
                {/*    content={`Contacter les deux conjoints`}*/}
                {/*    iconLeft={{ name: 'Chat', size: 'small' }}*/}
                {/*    marginLeft={{ xs: 'space16' }}*/}
                {/*    size="small"*/}
                {/*    onClick={() => {*/}
                {/*      onClickContactUser(*/}
                {/*        data.opportunity.groupedConversationId,*/}
                {/*      );*/}
                {/*    }}*/}
                {/*  />*/}
                {/*)}*/}
              </Flex>
            )}
            {isCustomer && closeStep && (
              <Flex>
                <Button
                  content={t('opportunity.see_final')}
                  primary
                  onClick={() => {
                    history.push(ClientAppRoutes.ADMINISTRATIVE_DOCUMENTS);
                  }}
                />
              </Flex>
            )}
            {!isCustomer && closeStep && (
              <Flex>
                <Button
                  content={t('opportunity.see_final')}
                  primary
                  onClick={() => {
                    history.push({
                      pathname: location.pathname,
                      search: '?view=administrative-documents',
                    });
                  }}
                  iconRight={{ name: 'ArrowRightSquare' }}
                />
              </Flex>
            )}
          </Flex>

          {!closeStep ? (
            <Flex width="225px" marginLeft={{ xs: 'space32' }}>
              <Flex alignItems="center" height="100%">
                <Text
                  content={t('opportunity.opportunity_progress')}
                  fontStyle="heading5"
                />
                <CircleProgress percentage={getPercentage()} />
              </Flex>
            </Flex>
          ) : (
            <Flex width="356px" marginLeft={{ xs: 'space32' }}>
              <Card padding="24px">
                <Flex alignItems="center" height="100%">
                  <Text
                    content={t('opportunity.opportunity_days_left')}
                    fontStyle={'heading5'}
                    marginRight={{ xs: 'space32' }}
                  />
                  <Flex direction={{ xs: 'column' }}>
                    <Text
                      content={daysBeforeDeletion.toString()}
                      fontStyle="heading3"
                      weight="bold"
                      color={theme.colors.salmon1}
                      textAlign="center"
                    />
                    <Text
                      content={'jours'}
                      fontStyle="heading3"
                      weight="bold"
                      color={theme.colors.salmon1}
                    />
                  </Flex>
                </Flex>
              </Card>
            </Flex>
          )}
        </FlexPadding>

        <Flex marginBottom={{ xs: 'space8' }}>
          <Line />
        </Flex>
        <FlexPadding direction={{ xs: 'column' }}>
          <Flex
            justify="start"
            style={{ gap: theme.spacing.space48 }}
            marginBottom={{ xs: !!closeStep ? 'none' : 'space32' }}
          >
            <SpecificationCardList specifications={specifications} />
          </Flex>
          <Requires value={!closeStep}>
            <Stepper
              steps={steps}
              isClient={isCustomer}
              defaultStep={currentStep?.order}
            />
          </Requires>
        </FlexPadding>
      </Card>
    </>
  );
};

export default StepperOpportunity;
