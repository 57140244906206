import { FC } from 'react';
import styled from 'styled-components';

import { theme } from 'theme';

import { Text } from 'components/Layout';

interface ChipProps {
  content: string;
}

const Wrapper = styled.div`
  padding: ${theme.spacing.space8} ${theme.spacing.space16};
  background-color: ${theme.colors.green1};
  border-radius: ${theme.spacing.space64};
`;

const Chip: FC<ChipProps> = ({ content }) => {
  return (
    <Wrapper>
      <Text
        fontStyle="label"
        color={theme.colors.white}
        content={content}
        whiteSpace="nowrap"
      />
    </Wrapper>
  );
};

export default Chip;
