import { FC, useState } from 'react';
import { Flex, Text } from 'components/Layout';

import { ClientAppRoutes } from 'ClientApp';
import { IconButton } from 'components/Buttons';
import { IconProps } from 'components/Images/Icon';
import { PickKey } from 'types/typescript';
import styled from 'styled-components';
import { theme } from 'theme';
import { useHistory } from 'react-router';
import {
  Procedure,
  ProcedureStep,
  ProcedureStepStatus,
  ProcedureStepType,
} from '../../../../types/resources';
import { env } from '../../../../config/env';

interface NotificationButtonProps {
  procedure: Procedure;
}

interface NotificationElement {
  onClick?: () => void;
  title?: string;
  label?: string;
  icon?: IconProps['name'];
}

type AccessorNotificationElement<T extends unknown> = Record<
  ProcedureStepType,
  Partial<
    Record<
      | ProcedureStepStatus.IN_PROGRESS
      | ProcedureStepStatus.IN_REVIEW
      | ProcedureStepStatus.LOCKED,
      T | undefined
    >
  >
>;

type CustomStepStatus = PickKey<
  typeof ProcedureStepStatus,
  'IN_PROGRESS' | 'IN_REVIEW'
>;

const Button = styled.button`
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.gray3};
  border-radius: ${theme.spacing.space16};
  background-color: ${theme.colors.beige};
  padding: 12px;
  cursor: pointer;
`;

const NotificationButton: FC<NotificationButtonProps> = ({ procedure }) => {
  const [hoverState, setHoverState] = useState(false);
  const history = useHistory();

  const handleClickContact = () => {
    history.push(ClientAppRoutes.MESSAGING);
  };

  const legalReflexionDelay = Number(env.procedure.legalReflectionPeriod);

  const getNotification = (currentStep: ProcedureStep): NotificationElement => {
    const icon: AccessorNotificationElement<IconProps['name']> = {
      FORM: {
        IN_PROGRESS: 'ArrowRight',
        IN_REVIEW: 'ArrowRight',
      },
      DOCUMENT: {
        IN_PROGRESS: 'ArrowRight',
        IN_REVIEW: 'TimeCircle',
      },
      VERIFICATION: {
        IN_PROGRESS: 'TimeCircle',
      },
      WRITING_AGREEMENT: {
        IN_PROGRESS: 'TimeCircle',
        IN_REVIEW: 'TimeCircle',
      },
      REVIEW_AGREEMENT: {
        LOCKED: 'TimeCircle',
        IN_PROGRESS: 'ArrowRight',
        IN_REVIEW: 'TimeCircle',
      },
      AGREEMENT_SENT: {
        IN_PROGRESS: 'TimeCircle',
        IN_REVIEW: 'TimeCircle',
      },
      SIGNATURE: {
        IN_PROGRESS: 'ArrowRight',
        IN_REVIEW: 'TimeCircle',
      },
      ARCHIVE_SIGN: {
        IN_PROGRESS: 'TimeCircle',
      },
      NOTARY: {
        IN_PROGRESS: 'ArrowRight',
        IN_REVIEW: 'ArrowRight',
      },
      MARITAL_STATUS: {
        IN_PROGRESS: 'ArrowRight',
        IN_REVIEW: 'ArrowRight',
      },
      FINAL_SENDING: {
        IN_PROGRESS: 'ArrowRight',
        IN_REVIEW: 'ArrowRight',
      },
      CLOSED: {},
    };

    const title: AccessorNotificationElement<string> = {
      FORM: {
        IN_PROGRESS: 'Veuillez remplir et valider vos formulaires',
        IN_REVIEW: 'Veuillez remplir et valider vos formulaires',
      },
      DOCUMENT: {
        IN_PROGRESS: 'Veuillez téléverser et valider vos documents',
        IN_REVIEW:
          'Vos documents sont en cours de vérification par votre gestionnaire. <br /> Les délais de traitement sont de 7 jours ouvrés (hors samedi, dimanche et jour férié).',
      },
      VERIFICATION: {
        IN_PROGRESS:
          'Votre dossier est en cours de vérification par nos services (temps de traitement moyen 72 heures ouvrées)',
      },
      WRITING_AGREEMENT: {
        IN_PROGRESS:
          'Votre dossier est complet, le projet de convention de divorce est en cours de rédaction (temps de traitement moyen 10 jours ouvrés)',
        IN_REVIEW:
          'Votre dossier est complet, le projet de convention de divorce est en cours de rédaction (temps de traitement moyen 10 jours ouvrés)',
      },
      REVIEW_AGREEMENT: {
        LOCKED: 'Votre convention de divorce est en cours de modification',
        IN_PROGRESS:
          'Veuillez lire, vérifier et valider votre convention de divorce',
        IN_REVIEW:
          'Votre convention est en cours de modification par vos avocats',
      },
      AGREEMENT_SENT: {
        IN_PROGRESS: 'Vos recommandés sont en cours d’envoi',
        IN_REVIEW:
          "Vos recommandés ont été envoyés et nous sommes en attente de l'accusé de reception",
      },
      SIGNATURE: {
        IN_PROGRESS: `Votre délai de réflexion de ${legalReflexionDelay} jours est en cours, vous pouvez cependant prendre votre rendez-vous de signature finale`,
        IN_REVIEW:
          'Vous êtes actuellement en attente de votre rendez-vous de signature de votre convention de divorce',
      },
      ARCHIVE_SIGN: {
        IN_PROGRESS:
          "Nous avons bien reçu la signature de la convention de divorce. Elle est en train d'être archivée",
      },
      NOTARY: {
        IN_PROGRESS:
          "La demande d'enregistrement notarié va être envoyée dans les prochains jours",
        IN_REVIEW:
          'Le notaire procède à l’enregistrement de votre divorce au rang de ses minutes (délai légal 3 semaines)',
      },
      MARITAL_STATUS: {
        IN_PROGRESS:
          'Votre avocat s’occupe de la transcription du divorce auprès de l’état civil (France uniquement)',
        IN_REVIEW:
          'Votre avocat s’occupe de la transcription du divorce auprès de l’état civil (France uniquement)',
      },
      FINAL_SENDING: {
        IN_PROGRESS: 'Votre dossier complet va vous être envoyé par courrier',
        IN_REVIEW: 'Votre dossier vous a été envoyé',
      },
      CLOSED: {},
    };

    const label: AccessorNotificationElement<string> = {
      FORM: {
        IN_PROGRESS: 'Accéder aux formulaires',
        IN_REVIEW: 'Accéder aux formulaires',
      },
      DOCUMENT: {
        IN_PROGRESS: 'Accéder aux documents',
      },
      VERIFICATION: {
        IN_PROGRESS: 'Contacter mon conseiller',
      },
      WRITING_AGREEMENT: {
        IN_PROGRESS: 'Contacter mon conseiller',
      },
      REVIEW_AGREEMENT: {
        LOCKED: 'Accéder à la convention',
        IN_PROGRESS: 'Accéder à la convention',
        IN_REVIEW: 'Accéder à la convention',
      },
      AGREEMENT_SENT: {
        IN_PROGRESS: 'Accéder à mon recommandé',
        IN_REVIEW: 'Accéder à mon recommandé',
      },
      SIGNATURE: {
        IN_PROGRESS: 'Prendre rendez-vous',
        IN_REVIEW: 'Accéder au rendez-vous',
      },
      ARCHIVE_SIGN: {
        IN_PROGRESS: 'Contacter mon conseiller',
      },
      NOTARY: {
        IN_PROGRESS: 'Contacter mon conseiller',
        IN_REVIEW: 'Accéder',
      },
      MARITAL_STATUS: {
        IN_PROGRESS: 'Contacter mon conseiller',
        IN_REVIEW: 'Accéder',
      },
      FINAL_SENDING: {
        IN_PROGRESS: "Suivre l'envoi",
        IN_REVIEW: "Suivre l'envoi",
      },
      CLOSED: {
        IN_PROGRESS: 'Contacter mon conseiller',
      },
    };

    const onClick: AccessorNotificationElement<() => void> = {
      FORM: {
        IN_PROGRESS: () => history.push(ClientAppRoutes.FORMS),
        IN_REVIEW: () => history.push(ClientAppRoutes.FORMS),
      },
      DOCUMENT: {
        IN_PROGRESS: () => history.push(ClientAppRoutes.DOCUMENTS),
      },
      VERIFICATION: {
        IN_PROGRESS: () => handleClickContact(),
      },
      WRITING_AGREEMENT: {
        IN_PROGRESS: () => handleClickContact(),
      },
      REVIEW_AGREEMENT: {
        LOCKED: () => history.push(ClientAppRoutes.CONVENTION),
        IN_PROGRESS: () => history.push(ClientAppRoutes.CONVENTION),
        IN_REVIEW: () => history.push(ClientAppRoutes.CONVENTION),
      },
      AGREEMENT_SENT: {
        IN_PROGRESS: () => history.push(ClientAppRoutes.REGISTERED_LETTERS),
        IN_REVIEW: () => history.push(ClientAppRoutes.REGISTERED_LETTERS),
      },
      SIGNATURE: {
        IN_PROGRESS: () => history.push(ClientAppRoutes.APPOINTMENT),
        IN_REVIEW: () => history.push(ClientAppRoutes.APPOINTMENT),
      },
      ARCHIVE_SIGN: {
        IN_PROGRESS: () => handleClickContact(),
      },
      NOTARY: {
        IN_PROGRESS: () => handleClickContact(),
        IN_REVIEW: () => history.push(ClientAppRoutes.FORMALITY_NOTARY),
      },
      MARITAL_STATUS: {
        IN_PROGRESS: () => handleClickContact(),
        IN_REVIEW: () => history.push(ClientAppRoutes.FORMALITY_MARITAL_STATUS),
      },
      FINAL_SENDING: {
        IN_PROGRESS: () =>
          history.push(ClientAppRoutes.FORMALITY_FINAL_SENDING),
        IN_REVIEW: () => history.push(ClientAppRoutes.FORMALITY_FINAL_SENDING),
      },
      CLOSED: {
        IN_PROGRESS: () => {
          history.push(ClientAppRoutes.ADMINISTRATIVE_DOCUMENTS);
        },
      },
    };

    return {
      onClick: currentStep
        ? onClick[currentStep.step][currentStep.status as CustomStepStatus]
        : () => {},
      title: currentStep
        ? title[currentStep.step][currentStep.status as CustomStepStatus]
        : '',
      label: currentStep
        ? label[currentStep.step][currentStep.status as CustomStepStatus]
        : '',
      icon: currentStep
        ? icon[currentStep.step][currentStep.status as CustomStepStatus]
        : undefined,
    };
  };

  if (!procedure.current_step) return null;

  const { label, title, onClick, icon } = getNotification(
    procedure.current_step,
  );

  if (title && icon) {
    return (
      <Flex marginRight={{ xs: 'space16' }}>
        <Button
          onClick={onClick}
          onMouseEnter={() => setHoverState(true)}
          onMouseLeave={() => setHoverState(false)}
        >
          <IconButton
            color={
              hoverState && onClick ? theme.colors.gray6 : theme.colors.black
            }
            backgroundColor="transparent"
            iconName={icon}
          />
          <Text
            fontStyle="body2"
            dangerouslySetInnerHTML={{ __html: title }}
            weight="medium"
            color={
              hoverState && onClick ? theme.colors.gray6 : theme.colors.black
            }
          />
          {label && onClick && (
            <Flex marginLeft={{ xs: 'space16' }} alignItems="center">
              <IconButton
                iconName="ArrowRight"
                color={theme.colors.green1}
                backgroundColor={theme.colors.green2}
                rounded
                size="small"
              />
              <Text
                fontStyle="body3"
                content={label}
                weight="bold"
                whiteSpace="nowrap"
                color={hoverState ? theme.colors.green2 : theme.colors.green1}
                transform="uppercase"
                marginLeft={{ xs: 'space16' }}
              />
            </Flex>
          )}
        </Button>
      </Flex>
    );
  }
  return null;
};

export default NotificationButton;
