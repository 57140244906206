import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';

import { Container, DesktopOnly, Requires, Text } from 'components/Layout';

import { md } from 'theme/styles/mediaQueries';
import { ClientConversation } from 'container/client';
import { Trans, useTranslation } from 'react-i18next';
import { useCustomer } from '../../hooks/useCustomer';

const CustomContainer = styled(Container)`
  padding: 0;
  margin: 0;
  min-width: 100%;

  ${md(`
    padding-top: ${theme.spacing.space32};
    padding-bottom: ${theme.spacing.space32};
    margin: 0 auto;
    min-width: unset;
  `)}
`;

const ClientConversationPage: FC = () => {
  const { t } = useTranslation();
  const { customer } = useCustomer();

  useEffect(() => {
    if (!window?.$crisp) return;

    window?.$crisp?.push(['do', 'chat:hide']);

    return () => {
      window.$crisp.push(['do', 'chat:show']);
    };
  }, [window?.$crisp]);

  if (!customer?.procedure_id) return null;

  return (
    <Requires value={customer.procedure_id}>
      <CustomContainer>
        <DesktopOnly>
          <Text
            content={t('opportunity.conversation.title')}
            fontStyle="heading2"
            marginBottom={{ xs: 'space16' }}
          />
          <Text fontStyle="body1" marginBottom={{ xs: 'space24' }}>
            <Trans i18nKey="opportunity.conversation.description" />
          </Text>
        </DesktopOnly>
        <ClientConversation
          customerId={customer.id}
          caseId={customer.procedure_id}
        />
      </CustomContainer>
    </Requires>
  );
};

export default ClientConversationPage;
