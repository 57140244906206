import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import {
  Breadcrumbs,
  CustomerUpdateForm,
  EstimatesList,
  PaymentsList,
} from 'container/admin';
import { useHistory, useRouteMatch } from 'react-router';
import { get } from 'lodash';
import { useApi } from '../../hooks/useApi';
import format from 'date-fns/format';
import { theme } from '../../theme';
import { InlineButton } from '../../components/Buttons';
import { Modal } from '../../components/Modal';
import { ConfirmForm } from '../../components/ConfirmPopin/ConfirmPopin';
import useToaster from '../../hooks/useToaster';
import styled from 'styled-components';
import { CustomerFull } from '../../types/resources';
import { getRouteWithParams } from '../../utils/router';
import { AdminAppRoutes } from '../../AdminApp';

const ActionsContainer = styled.div`
  margin-top: ${theme.spacing.space48};
  border-radius: 8px;
  padding: ${theme.spacing.space16};
  background-color: ${theme.colors.salmon3};
`;

const CustomersDetails: FC = () => {
  const { t } = useTranslation();
  const { success, error } = useToaster();
  const history = useHistory();
  const match = useRouteMatch();
  const customerId = get(match, 'params.id', '');
  const [resetPasswordModalIsOpen, setResetPasswordModalIsOpen] =
    useState<boolean>(false);
  const {
    execute: resetPasswordRequest,
    state: { loading },
  } = useApi(`/customers/${customerId}/reset-password`, {
    method: 'GET',
  });
  const { execute: getCustomer, state: getCustomerState } =
    useApi<CustomerFull>(`/customers/${customerId}`);
  const customer = getCustomerState.data?.value;

  useEffect(() => {
    getCustomer();
  }, []);

  const handleSendResetPassword = () => {
    setResetPasswordModalIsOpen(true);
  };

  const onCloseResetPassword = () => {
    setResetPasswordModalIsOpen(false);
  };

  const handleResetPasswordConfirm = async () => {
    resetPasswordRequest({
      onSuccess: () => {
        onCloseResetPassword();
        success(t('customer.details.reset_password.success'));
      },
      onError: () => {
        error(t('customer.details.reset_password.error'));
      },
    });
  };

  const handlePhoneCall = useCallback(() => {
    if (customer?.phone) {
      window.open(`tel:${customer.phone}`);
    }
  }, [customer?.phone]);

  const handleSendMessage = useCallback(() => {
    if (customer?.procedure_id) {
      history.push({
        pathname: getRouteWithParams(AdminAppRoutes.OPPORTUNITY_MESSAGING, {
          id: customer.procedure_id,
        }),
        search: `?spouse=${customer.id}`,
      });
    }
  }, [customer?.procedure_id]);

  return (
    <>
      <Modal opened={resetPasswordModalIsOpen} onClose={onCloseResetPassword}>
        <ConfirmForm
          title={t('customer.details.reset_password.title')}
          description={t('customer.details.reset_password.description')}
          confirmText={t('customer.details.reset_password.confirm')}
          handleSubmit={handleResetPasswordConfirm}
          setModalIsOpen={setResetPasswordModalIsOpen}
          loading={loading}
        />
      </Modal>
      <Breadcrumbs name={customer?.full_name} />
      <Flex
        marginBottom={{ xs: 'space16' }}
        alignItems="center"
        justify="between"
      >
        <Text content={t('customer.details.title')} fontStyle="heading2" />

        {customer && (
          <Text
            content={t('updated_at', {
              updated_at: format(
                new Date(customer.updated_at),
                "dd/MM/YYY 'à' HH:mm",
              ),
            })}
            fontStyle="body3"
          />
        )}
      </Flex>

      {customer && <CustomerUpdateForm customer={customer} />}

      {customer && (
        <>
          <Flex marginTop={{ xs: 'space48' }}>
            <Text content={t('payments.title')} fontStyle="heading3" />
          </Flex>
          <PaymentsList
            pageSize={3}
            customerId={customer.id}
            estimateId={null}
            status={null}
          />
        </>
      )}

      {customer && (
        <>
          <Flex marginTop={{ xs: 'space48' }}>
            <Text content={t('estimate.estimates')} fontStyle="heading3" />
          </Flex>
          <EstimatesList pageSize={3} customerId={customer.id} search={null} />
        </>
      )}

      {customer && (
        <ActionsContainer>
          <Flex direction={{ xs: 'column' }}>
            <InlineButton
              onClick={handleSendResetPassword}
              iconColor={theme.colors.salmon1}
              text={t('customer.details.reset_password.label')}
              backgroundColor={theme.colors.white}
              iconName="ArrowRight"
              hoverBackgroundColor={theme.colors.salmon2}
              hoverIconColor={theme.colors.white}
              style={{ width: 'fit-content' }}
            />

            {customer.procedure_id && (
              <InlineButton
                onClick={handleSendMessage}
                iconColor={theme.colors.salmon1}
                text={t('customer.send_message')}
                backgroundColor={theme.colors.white}
                iconName="Send"
                hoverBackgroundColor={theme.colors.salmon2}
                hoverIconColor={theme.colors.white}
                style={{ width: 'fit-content', marginTop: '12px' }}
              />
            )}

            {customer.phone && (
              <InlineButton
                onClick={handlePhoneCall}
                iconColor={theme.colors.salmon1}
                text={t('customer.call')}
                backgroundColor={theme.colors.white}
                iconName="Call"
                hoverBackgroundColor={theme.colors.salmon2}
                hoverIconColor={theme.colors.white}
                style={{ width: 'fit-content', marginTop: '12px' }}
              />
            )}
          </Flex>
        </ActionsContainer>
      )}
    </>
  );
};

export default CustomersDetails;
