import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

// Components
import InputSelect from 'components/FormTemplate/Fields/InputSelect';

// data

// Utils
import { formatInputSelect } from 'utils/format';
import { useQueryParams } from 'hooks/useQueryParams';
import { toggleSearchParams } from 'utils/search';
import { Permission } from 'components/User';
import styled from 'styled-components';
import {
  ProcedureStepStatus,
  ProcedureStepType,
  Role,
  User,
} from '../../../types/resources';
import InputSelectAsync from '../../../components/FormTemplate/Fields/InputSelectAsync';
import { useApi } from '../../../hooks/useApi';

const GridWrapper = styled.div`
  display: grid;
  gap: 32px;
  margin-top: 40px;
  grid-auto-flow: column;
  width: 100%;
`;

const CasesListFilters: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const query = useQueryParams();
  const searchParams = new URLSearchParams(location.search);
  const statusOptions = formatInputSelect(ProcedureStepStatus);
  const { execute: getUsers } = useApi<User[]>('/users');

  const handleLawyerSearch = (
    val: string,
    callback: (options: any[]) => void,
  ) => {
    getUsers({
      query: {
        search: val || undefined,
        role: Role.LAWYER,
        sort: 'full_name',
        direction: 'asc',
      },
      onSuccess: (result) => {
        callback(
          result.value.map((c) => ({
            value: c.id,
            label: c.full_name,
          })),
        );
      },
    });
  };

  const handleManagerSearch = (
    val: string,
    callback: (options: any[]) => void,
  ) => {
    const query: Record<string, string> = {};
    if (val) {
      query.search = val;
    }

    getUsers({
      query: {
        search: val || undefined,
        role: Role.MANAGER,
        sort: 'full_name',
        direction: 'asc',
      },
      onSuccess: (result) => {
        callback(
          result.value.map((c) => ({
            value: c.id,
            label: c.full_name,
          })),
        );
      },
    });
  };

  const stepOptions = Object.values(ProcedureStepType).map((type) => ({
    label: t(`enums:${type}`),
    value: type,
  }));

  const handleStatusChange = (val: ProcedureStepStatus) => {
    toggleSearchParams('step_status', val, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const handleStepChange = (val: ProcedureStepType) => {
    toggleSearchParams('step_type', val, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const handleManagerChange = (val: any) => {
    toggleSearchParams('manager_id', val, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const handleLawyerChange = (val: any) => {
    toggleSearchParams('lawyer_id', val, searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const StatusValue = query.get('step_status') || '';
  const StepValue = query.get('step_type') || '';
  const ManagerValue = query.get('manager_id') || '';
  const LawyerValue = query.get('lawyer_id') || '';

  return (
    <GridWrapper>
      <InputSelect
        onChange={handleStepChange}
        options={stepOptions}
        placeholder={t('filter.search_by_step')}
        label={t('opportunity.columns.step')}
        labelWeight="bold"
        labelUppercase={true}
        size="small"
        isClearable
        value={StepValue && StepValue !== '' ? StepValue : ''}
      />
      <InputSelect
        onChange={handleStatusChange}
        options={statusOptions}
        label={t('opportunity.columns.step_status')}
        labelWeight="bold"
        labelUppercase={true}
        placeholder={t('filter.search_by_status')}
        size="small"
        isClearable
        value={StatusValue && StatusValue !== '' ? StatusValue : ''}
      />
      <Permission roles={[Role.ADMIN]}>
        <InputSelectAsync
          onChange={handleManagerChange}
          loadOptions={handleManagerSearch}
          defaultOptions
          placeholder={t('filter.search_by_manager')}
          label={t('opportunity.columns.manager')}
          labelWeight="bold"
          labelUppercase={true}
          size="small"
          isClearable
          disabled={LawyerValue !== ''}
          value={ManagerValue && ManagerValue !== '' ? ManagerValue : ''}
        />
      </Permission>
      <Permission roles={[Role.ADMIN]}>
        <InputSelectAsync
          onChange={handleLawyerChange}
          loadOptions={handleLawyerSearch}
          defaultOptions
          placeholder={t('filter.search_by_lawyer')}
          label={t('opportunity.columns.lawyer')}
          labelWeight="bold"
          labelUppercase={true}
          size="small"
          isClearable
          disabled={ManagerValue !== ''}
          value={LawyerValue && LawyerValue !== '' ? LawyerValue : ''}
        />
      </Permission>
    </GridWrapper>
  );
};

export default CasesListFilters;
