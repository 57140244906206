import { initAnalytics } from '../utils/analytics';

export type Env = {
  envName: string;
  procedure: { legalReflectionPeriod: string; deletionPeriod: string };
  api: { url: string };
  adobe: { clientId: string };
  pipedrive: { url: string };
  alma: { merchantId: string };
  crisp: { websiteId: string };
  gtag: { containerId: string };
  docusign: { url: string };
  sentry: { dsn: string };
  calendly: { url: string };
  zendesk: { accountKey: string };
  paypal: { clientId: string };
};

export let env: Env = {
  envName: '',
  procedure: {
    legalReflectionPeriod: '',
    deletionPeriod: '',
  },
  api: {
    url: '',
  },
  adobe: {
    clientId: '',
  },
  pipedrive: {
    url: '',
  },
  alma: {
    merchantId: '',
  },
  crisp: {
    websiteId: '',
  },
  gtag: {
    containerId: '',
  },
  docusign: {
    url: '',
  },
  sentry: {
    dsn: '',
  },
  calendly: {
    url: '',
  },
  zendesk: {
    accountKey: '',
  },
  paypal: {
    clientId: '',
  },
};

export async function initEnv() {
  const res = await fetch('/config.json');

  if (!res.ok) {
    throw new Error('config missing');
  } else {
    env = await res.json();
    const isProd = env.envName === 'production';
    if (!isProd) {
      console.warn('config', env);
    }

    initAnalytics(env);
  }
}
