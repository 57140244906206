import { env } from './env';

const externalUrls = {
  docusign: `${env.docusign.url}/documents/details/`,
};

const daysBeforeDelete = 180;

const dateFormat = 'dd/MM/yyyy';

const config = { externalUrls, daysBeforeDelete, dateFormat };

export default config;
