import { FC, useEffect, useMemo, useState } from 'react';
import { CellProps, Column, SortingRule } from 'react-table';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from 'components/Buttons/Button';
import Table from 'components/Table/Table';
import { Flex } from 'components/Layout/Flex';

import { AdminAppRoutes } from 'AdminApp';
import { getRouteWithParams } from 'utils/router';
import { useApi } from '../../../hooks/useApi';
import format from 'date-fns/format';
import { Estimate, SortDirection } from '../../../types/resources';

type EstimatesListProps = {
  pageSize: number;
  search: string | null;
  customerId?: string;
};

const EstimatesList: FC<EstimatesListProps> = ({
  pageSize,
  search,
  customerId,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [sortKey, setSortKey] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    undefined,
  );
  const history = useHistory();
  const {
    execute: getEstimates,
    state: { data },
  } = useApi<Estimate[]>('/estimates');
  const estimates = data?.value;

  useEffect(() => {
    getEstimates({
      query: {
        search: search || undefined,
        customer_id: customerId,
        page: page !== undefined ? page + 1 : 1,
        items: pageSize,
        sort: sortKey,
        direction: sortDirection,
      },
    });
  }, [search, page, sortKey, sortDirection]);

  const onLoadMore = ({
    pageIndex,
    sortBy,
  }: {
    pageIndex?: number;
    sortBy?: SortingRule<Estimate>[];
  }) => {
    if (pageIndex !== undefined) {
      setPage(pageIndex);
    }

    const _sortKey = sortBy?.[0]?.id;
    if (_sortKey !== undefined) {
      setSortKey(_sortKey);
    }

    const _sortDesc = sortBy?.[0]?.desc;
    if (_sortDesc !== undefined) {
      setSortDirection(_sortDesc ? SortDirection.DESC : SortDirection.ASC);
    }
  };

  const columns = useMemo<Column<Estimate>[]>(
    () => [
      {
        Header: t('estimate.name'),
        accessor: 'name',
        disableSortBy: true,
        width: 250,
      },
      {
        Header: t('estimate.price'),
        accessor: 'price',
        Cell: ({ value }) => `${value} €`,
        width: 100,
      },
      {
        Header: t('estimate.created_at'),
        accessor: 'created_at',
        Cell: ({ value }) => format(new Date(value), "dd/MM/YYY 'à' HH:mm"),
      },
      {
        Header: t('estimate.updated_at'),
        accessor: 'updated_at',
        Cell: ({ value }) => format(new Date(value), "dd/MM/YYY 'à' HH:mm"),
      },
      {
        Header: t('payments.case'),
        id: 'procedure',
        accessor: (originalRow) => originalRow,
        disableSortBy: true,
        width: 50,
        Cell: ({ cell }: CellProps<Estimate>) => {
          const estimate = cell.row.original;
          const procedure_id = estimate.procedure_id;
          const procedure_reference = estimate.procedure_reference;

          return procedure_id && procedure_reference ? (
            <Link to={`${AdminAppRoutes.CASES}/${procedure_id}`}>
              {procedure_reference}
            </Link>
          ) : null;
        },
      },
      {
        accessor: 'id',
        width: 55,
        disableSortBy: true,
        Cell: Object.assign(
          ({ cell }: CellProps<Estimate>) => (
            <Flex justify="end" expand>
              <Button
                content={t('view')}
                size="small"
                primary
                onClick={() =>
                  history.push(
                    getRouteWithParams(AdminAppRoutes.ESTIMATES_DETAILS, {
                      id: cell.row.values.id,
                    }),
                  )
                }
              />
            </Flex>
          ),
          { displayName: 'Button' },
        ),
      },
    ],
    [history],
  );

  return (
    <Table<Estimate>
      data={estimates || []}
      columns={columns}
      pageSize={pageSize}
      totalCount={data?.pagination?.total || 0}
      loadMore={onLoadMore}
      td={{ paddingVertical: 0 }}
    />
  );
};

export default EstimatesList;
