import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { env } from './env';

const history = createBrowserHistory();
export const initSentry = (): void =>
  Sentry.init({
    dsn: env.sentry.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
    environment: env.envName || 'unknown',
    enabled: !!env.sentry.dsn,
  });
