import { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';

// Components
import { AdminMenu } from 'container/admin';
import {
  AdminHome,
  UserUpdate,
  Document,
  Form,
  Opportunities,
  Opportunity,
  UserAdd,
  Users,
  Products,
  ProductsUpdate,
  OpportunityMessaging,
  CreateManualOpportunity,
  Customers,
  Prospects,
  Estimates,
  ProspectsAdd,
  ProductsAdd,
  CustomersDetails,
  CustomersAdd,
  EstimatesAdd,
  Notifications,
  Payments,
  PaymentsAdd,
  PaymentsDetails,
} from 'pages/admin';
import Router from 'types/router.types';

// Schema
import { Account, Page404, Form as ClientForm } from 'pages/common';
import { Container } from 'components/Layout';
import { theme } from 'theme';
import { md } from 'theme/styles/mediaQueries';
import { QuoteResumePage } from 'pages/public';
import ProspectsDetails from './pages/admin/ProspectsDetails';
import { EstimatesDetails } from './pages/admin';
import { PublicAppRoutes } from './PublicApp';
import { ClientAppRoutes } from './ClientApp';

const Wrapper = styled.div`
  display: grid;
  gap: 0;

  ${md(`
    grid-template-columns: 176px ${theme.spacing.space40} 1fr ${theme.spacing.space56};
  `)}
`;

const CustomContainer = styled(Container)`
  padding-top: 100px;
  padding-bottom: ${theme.spacing.space40};
  ${md(`
    padding-top: ${theme.spacing.space56};
    padding-bottom: ${theme.spacing.space40};
  `)}
`;

export enum AdminAppRoutes {
  HOME = '/',
  USERS = '/utilisateurs',
  CUSTOMERS = '/clients',
  CUSTOMERS_DETAILS = '/clients/:id',
  CUSTOMERS_ADD = '/clients/ajout',
  PROSPECTS = '/prospects',
  PROSPECTS_DETAILS = '/prospects/:id',
  PROSPECTS_ADD = '/prospects/ajout',
  ESTIMATE = '/devis',
  ESTIMATES_ADD = '/devis/ajout',
  ESTIMATES_DETAILS = '/devis/:id',
  NOTIFICATIONS = '/notifications',
  PRODUCT = '/produits',
  PRODUCTS_ADD = '/produits/ajout',
  PRODUCTS_DETAILS = '/produits/:id',
  PAYMENTS = '/paiements',
  PAYMENTS_ADD = '/paiements/ajout',
  PAYMENTS_DETAILS = '/paiements/:id',
  USER_UPDATE_ID = '/utilisateurs/edition/:id',
  USER_ADD = '/utilisateurs/ajout',
  CASES = '/dossiers',
  ACCOUNT = '/mon-compte',
  CASES_DETAILS = '/dossiers/:id',
  OPPORTUNITY_MESSAGING = '/dossiers/:id/messagerie',
  FORM = '/admin/formulaire/:opportunityId/:formId',
  DOCUMENT = '/document/:opportunityId/:documentId',
  CLIENT_FORM = '/modification-formulaire/:id/:opportunityId',
  CREATE_MANUAL_OPPORTUNITY = '/affaires/creation-dossier/:dealId',
  QUOTE_RESUME = '/affaires/recapitulatif/:id',
  SIGN_IN = '/connexion',
}

const routes: Record<AdminAppRoutes, Router> = {
  [AdminAppRoutes.HOME]: {
    routeProps: {
      path: AdminAppRoutes.HOME,
      exact: true,
      component: AdminHome,
    },
  },
  [AdminAppRoutes.CLIENT_FORM]: {
    routeProps: {
      path: AdminAppRoutes.CLIENT_FORM,
      exact: true,
      component: ClientForm,
    },
  },
  [AdminAppRoutes.ACCOUNT]: {
    routeProps: {
      path: AdminAppRoutes.ACCOUNT,
      exact: true,
      component: Account,
    },
  },
  [AdminAppRoutes.USERS]: {
    routeProps: {
      path: AdminAppRoutes.USERS,
      exact: true,
      component: Users,
    },
  },
  [AdminAppRoutes.ESTIMATE]: {
    routeProps: {
      path: AdminAppRoutes.ESTIMATE,
      exact: true,
      component: Estimates,
    },
  },
  [AdminAppRoutes.ESTIMATES_ADD]: {
    routeProps: {
      path: AdminAppRoutes.ESTIMATES_ADD,
      exact: true,
      component: EstimatesAdd,
    },
  },
  [AdminAppRoutes.ESTIMATES_DETAILS]: {
    routeProps: {
      path: AdminAppRoutes.ESTIMATES_DETAILS,
      exact: true,
      component: EstimatesDetails,
    },
  },
  [AdminAppRoutes.NOTIFICATIONS]: {
    routeProps: {
      path: AdminAppRoutes.NOTIFICATIONS,
      exact: true,
      component: Notifications,
    },
  },
  [AdminAppRoutes.PROSPECTS]: {
    routeProps: {
      path: AdminAppRoutes.PROSPECTS,
      exact: true,
      component: Prospects,
    },
  },
  [AdminAppRoutes.PROSPECTS_ADD]: {
    routeProps: {
      path: AdminAppRoutes.PROSPECTS_ADD,
      exact: true,
      component: ProspectsAdd,
    },
  },
  [AdminAppRoutes.PROSPECTS_DETAILS]: {
    routeProps: {
      path: AdminAppRoutes.PROSPECTS_DETAILS,
      exact: true,
      component: ProspectsDetails,
    },
  },
  [AdminAppRoutes.CUSTOMERS]: {
    routeProps: {
      path: AdminAppRoutes.CUSTOMERS,
      exact: true,
      component: Customers,
    },
  },
  [AdminAppRoutes.CUSTOMERS_ADD]: {
    routeProps: {
      path: AdminAppRoutes.CUSTOMERS_ADD,
      exact: true,
      component: CustomersAdd,
    },
  },
  [AdminAppRoutes.CUSTOMERS_DETAILS]: {
    routeProps: {
      path: AdminAppRoutes.CUSTOMERS_DETAILS,
      exact: true,
      component: CustomersDetails,
    },
  },
  [AdminAppRoutes.PRODUCT]: {
    routeProps: {
      path: AdminAppRoutes.PRODUCT,
      exact: true,
      component: Products,
    },
  },
  [AdminAppRoutes.PRODUCTS_ADD]: {
    routeProps: {
      path: AdminAppRoutes.PRODUCTS_ADD,
      exact: true,
      component: ProductsAdd,
    },
  },
  [AdminAppRoutes.PRODUCTS_DETAILS]: {
    routeProps: {
      path: AdminAppRoutes.PRODUCTS_DETAILS,
      exact: true,
      component: ProductsUpdate,
    },
  },
  [AdminAppRoutes.PAYMENTS]: {
    routeProps: {
      path: AdminAppRoutes.PAYMENTS,
      exact: true,
      component: Payments,
    },
  },
  [AdminAppRoutes.PAYMENTS_ADD]: {
    routeProps: {
      path: AdminAppRoutes.PAYMENTS_ADD,
      exact: true,
      component: PaymentsAdd,
    },
  },
  [AdminAppRoutes.PAYMENTS_DETAILS]: {
    routeProps: {
      path: AdminAppRoutes.PAYMENTS_DETAILS,
      exact: true,
      component: PaymentsDetails,
    },
  },
  [AdminAppRoutes.USER_ADD]: {
    routeProps: {
      path: AdminAppRoutes.USER_ADD,
      exact: true,
      component: UserAdd,
    },
  },
  [AdminAppRoutes.USER_UPDATE_ID]: {
    routeProps: {
      path: AdminAppRoutes.USER_UPDATE_ID,
      exact: true,
      component: UserUpdate,
    },
  },
  [AdminAppRoutes.CASES]: {
    routeProps: {
      path: AdminAppRoutes.CASES,
      exact: true,
      component: Opportunities,
    },
  },
  [AdminAppRoutes.CASES_DETAILS]: {
    routeProps: {
      path: AdminAppRoutes.CASES_DETAILS,
      exact: true,
      component: Opportunity,
    },
  },
  [AdminAppRoutes.OPPORTUNITY_MESSAGING]: {
    routeProps: {
      path: AdminAppRoutes.OPPORTUNITY_MESSAGING,
      exact: true,
      component: OpportunityMessaging,
    },
  },
  [AdminAppRoutes.FORM]: {
    routeProps: {
      path: AdminAppRoutes.FORM,
      exact: true,
      component: Form,
    },
  },
  [AdminAppRoutes.DOCUMENT]: {
    routeProps: {
      path: AdminAppRoutes.DOCUMENT,
      exact: true,
      component: Document,
    },
  },
  [AdminAppRoutes.CREATE_MANUAL_OPPORTUNITY]: {
    routeProps: {
      path: AdminAppRoutes.CREATE_MANUAL_OPPORTUNITY,
      exact: true,
      component: CreateManualOpportunity,
    },
  },
  [AdminAppRoutes.QUOTE_RESUME]: {
    routeProps: {
      path: AdminAppRoutes.QUOTE_RESUME,
      exact: true,
      component: QuoteResumePage,
    },
    isMobile: true,
  },
  [PublicAppRoutes.SIGN_IN]: {
    routeProps: {
      path: PublicAppRoutes.SIGN_IN,
      exact: true,
      component: () => <Redirect to={ClientAppRoutes.HOME} />,
    },
  },
};

const AdminApp: FC = () => {
  return (
    <Wrapper>
      <AdminMenu />
      <div></div>
      <CustomContainer fluid="lg">
        <Switch>
          {Object.values(routes).map((route) => (
            <Route
              key={`adminRoute-${route.routeProps.path}`}
              {...route.routeProps}
            />
          ))}
          <Route>
            <Page404 isAdmin={true} />
          </Route>
        </Switch>
      </CustomContainer>
    </Wrapper>
  );
};

export default AdminApp;
