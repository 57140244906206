import { CSSProperties, FC, ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';

import { theme } from 'theme';
import { breakpoints } from 'theme/styles/breakpoints';
import { offset } from 'theme/styles/size';
import { useViewport } from 'hooks/useViewport';

interface WrapperProps {
  opened: boolean;
}

interface ModalContainerProps {
  width?: string;
  height?: string;
  isMobile: boolean;
  full?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  z-index: -2;
  opacity: 0;
  transition: all 0.5s ease;
  overflow: unset;

  ${(props) =>
    props.opened &&
    `
    z-index: 12;
    opacity: 1;
  `}
`;

const Shadow = styled.div`
  position: fixed;
  z-index: 3;
  inset: 0;
  background-color: ${transparentize(0.33, theme.colors.black)};
`;

const ModalContainer = styled.div<ModalContainerProps>`
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${(props) =>
    props.full
      ? '100%'
      : !props.isMobile
      ? props.width
      : `calc(100% - ${offset * 2}px)`};
  top: 50%;
  transform: translateY(-50%);
  left: ${(props) => (props.full ? 0 : `${offset}px`)};
  overflow: auto;
  z-index: 13;
  background-color: ${theme.colors.white};
  border-radius: ${theme.spacing.space24};
  padding: ${(props) =>
    props.full
      ? `${theme.spacing.space32} ${theme.spacing.space16}`
      : `${theme.spacing.space32} ${theme.spacing.space24}`};
  max-height: ${(props) => (props.full ? '100%' : 'calc(100% - 240px)')};

  ${(props) =>
    props.height &&
    `
      height: ${props.height};
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
  `}

  @media screen and (min-width: ${breakpoints.md}) {
    width: ${(props) => props.width || 'auto'};
    max-width: 1120px;
    height: ${(props) => props.height || 'auto'};
    padding: ${theme.spacing.space40};
    top: 50vh;
    left: 50%;
    margin: 0;
    max-height: 90vh;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: ${breakpoints.md}) {
    max-height: 90vh;
  }
`;

export interface ModalProps {
  children: ReactNode;
  opened?: boolean;
  width?: string;
  style?: CSSProperties;
  height?: string;
  onClose?: () => void;
  full?: boolean;
  className?: string;
}

export const Modal: FC<ModalProps> = ({
  children,
  opened = true,
  height,
  width,
  onClose,
  full,
  style,
  className,
}) => {
  const { isMobile } = useViewport();
  useEffect(() => {
    if (opened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [opened]);

  return (
    <Wrapper opened={opened}>
      <Shadow onClick={onClose} />
      <ModalContainer
        style={style}
        width={width}
        height={height}
        isMobile={isMobile}
        full={full}
        className={className}
      >
        {children}
      </ModalContainer>
    </Wrapper>
  );
};
