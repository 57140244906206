import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Container
import { PaymentsList, PaymentsListFilters } from 'container/admin';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { Button } from '../../components/Buttons';
import { AdminAppRoutes } from '../../AdminApp';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';

const Payments: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const query = useQueryParams();
  const customerId = query.get('customer_id');
  const estimateId = query.get('estimate_id');
  const status = query.get('status');

  return (
    <>
      <Flex justify="between">
        <Text content={t('payments.title')} fontStyle="heading2" />
        <Button
          content={t('payments.add.new')}
          iconLeft={{ name: 'Plus' }}
          onClick={() => history.push(AdminAppRoutes.PAYMENTS_ADD)}
        />
      </Flex>
      <PaymentsListFilters />
      <Flex marginTop={{ xs: 'space32' }}>
        <PaymentsList
          pageSize={10}
          customerId={customerId}
          estimateId={estimateId}
          status={status}
        />
      </Flex>
    </>
  );
};

export default Payments;
