import { ArrayFieldTemplateProps } from '@rjsf/core';
import { Flex } from 'components/Layout';
import { Label } from 'components/Text/Label';
import styled from 'styled-components';
import { theme } from 'theme';
import { AllFormsData } from 'wrapper/FormWrapper';
import { InputPlusMinus } from '../Fields/InputPlusMinus';

const FlexWithBottomBorder = styled(Flex)`
  padding-top: 32px;
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.gray3};
    padding-bottom: 32px;
  }
`;

export const ArrayFormTemplate = <T extends AllFormsData>(
  props: ArrayFieldTemplateProps<T>,
) => {
  const { items, canAdd, onAddClick, title, schema } = props;

  return (
    <div>
      {canAdd && (
        <Flex alignItems="start" direction={{ xs: 'column' }}>
          <Label
            dangerouslySetInnerHTML={{ __html: title }}
            marginBottom={{ xs: 'space16' }}
          />
          <InputPlusMinus
            value={items.length}
            removeValue={items[items.length - 1]?.onDropIndexClick(
              items.length - 1,
            )}
            addValue={onAddClick}
            minItems={schema.minItems}
            maxItems={schema.maxItems}
          />
        </Flex>
      )}
      {items.map((element) => (
        <FlexWithBottomBorder
          alignItems="start"
          direction={{ xs: 'column' }}
          key={element.key}
        >
          {element.children}
        </FlexWithBottomBorder>
      ))}
    </div>
  );
};
