import { FC, useEffect, useMemo, useState } from 'react';
import { CellProps, Column, SortingRule } from 'react-table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from 'components/Buttons/Button';
import Table from 'components/Table/Table';
import { Flex } from 'components/Layout/Flex';

import { AdminAppRoutes } from 'AdminApp';
import { getRouteWithParams } from 'utils/router';
import { useApi } from '../../../hooks/useApi';
import { Icon } from '../../../components/Images';
import { theme } from '../../../theme';
import {
  Department,
  Role,
  SortDirection,
  User,
} from '../../../types/resources';

type UsersListProps = {
  pageSize: number;
  search: string | null;
  role: string | null;
  department: string | null;
  departments: Department[];
};

const UsersList: FC<UsersListProps> = ({
  pageSize,
  search,
  role,
  department,
  departments,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [sortKey, setSortKey] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    undefined,
  );
  const history = useHistory();
  const {
    execute: getUsers,
    state: { data },
  } = useApi<User[]>('/users');
  const value = data?.value;

  useEffect(() => {
    getUsers({
      query: {
        page: page !== undefined ? page + 1 : 1,
        items: pageSize,
        search: search || undefined,
        role: role || undefined,
        roles: [Role.ADMIN, Role.LAWYER, Role.MANAGER, Role.SALES],
        department_code: department || undefined,
        sort: sortKey,
        direction: sortDirection,
      },
    });
  }, [role, department, search, page, sortKey, sortDirection]);

  const onLoadMore = ({
    pageIndex,
    sortBy,
  }: {
    pageIndex?: number;
    sortBy?: SortingRule<User>[];
  }) => {
    if (pageIndex !== undefined) {
      setPage(pageIndex);
    }

    const _sortKey = sortBy?.[0]?.id;
    if (_sortKey !== undefined) {
      setSortKey(_sortKey);
    }

    const _sortDesc = sortBy?.[0]?.desc;
    if (_sortDesc !== undefined) {
      setSortDirection(_sortDesc ? SortDirection.DESC : SortDirection.ASC);
    }
  };

  const columns = useMemo<Column<User>[]>(
    () => [
      {
        Header: t('user.last_name'),
        accessor: 'last_name',
        width: 125,
      },
      {
        Header: t('user.first_name'),
        accessor: 'first_name',
        width: 125,
      },
      {
        Header: t('user.department'),
        accessor: 'department_code',
        width: 175,
        Cell: ({ value }) =>
          departments.find((d) => d.code === value)?.full_name ?? '',
      },
      {
        Header: t('user.user_role'),
        accessor: 'role',
        width: 175,
        disableSortBy: true,
        Cell: ({ value }) => t(`role.${value.toLowerCase()}`),
      },
      {
        Header: t('user.activated'),
        accessor: 'activated',
        width: 100,
        Cell: ({ value }) => (
          <Icon
            name={value ? 'check' : 'close'}
            fill="none"
            primaryColor={value ? theme.colors.green3 : theme.colors.red1}
          />
        ),
      },
      {
        Header: Object.assign(
          ({}) => (
            <Flex justify="end">
              <></>
            </Flex>
          ),
          {
            displayName: 'Id',
          },
        ),
        accessor: 'id',
        width: 100,
        disableSortBy: true,
        Cell: Object.assign(
          ({ cell }: CellProps<User>) => (
            <Flex justify="end" expand>
              <Button
                content={t('view')}
                size="small"
                primary
                onClick={() =>
                  history.push(
                    getRouteWithParams(AdminAppRoutes.USER_UPDATE_ID, {
                      id: cell.row.values.id,
                    }),
                  )
                }
              />
            </Flex>
          ),
          { displayName: 'Button' },
        ),
      },
    ],
    [history],
  );

  return (
    <Table<User>
      data={value || []}
      columns={columns}
      pageSize={pageSize}
      totalCount={data?.pagination?.total || 0}
      loadMore={onLoadMore}
      td={{ paddingVertical: 0 }}
    />
  );
};

export default UsersList;
